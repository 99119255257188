import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons'

import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import ArrowDown from '@proveoeng/uikit/dist/atoms/Icons/ArrowDown'
import ArrowRight from '@proveoeng/uikit/dist/atoms/Icons/ArrowRight'
import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { FormatDate } from '../../../../common/mappers/FormatDate'
import { parseToString } from '../../../../../lib/utils/Format'
import { ModalDetailProjectExecution } from './ModalDetailProjectExecution'

export const DetailProjectExecution = ({ element }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [modalToOpen, setModalToOpen] = useState({ show: false, element: {} })
  const handleClose = () => setModalToOpen({ show: false, element: {} })

  return (
    <>
      <Flex alignItems="center" paddingX={3} paddingY={3} width="100%" bg="white2">
        <Icon
          style={{ cursor: 'pointer' }}
          sizeIcon="display18"
          color="black"
          marginRight={4}
          onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? <ArrowDown /> : <ArrowRight />}
        </Icon>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex flexDirection="column">
            <Text
              as="span"
              sizeText="display16"
              color="black"
              fontWeight="medium"
              paddingBottom={1}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpened(!isOpened)}>
              {`${element.bidPackageName} (${element.sellerName})`}
            </Text>
            <Text
              as="span"
              sizeText="display14"
              color="black"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpened(!isOpened)}>
              {FormatDate.hydrate(element.declaredAt)?.readableReverseDate}
            </Text>
          </Flex>
          <Button colorType="none" sizeButton="none" action={() => {}}>
            <FontAwesomeIcon
              icon={faEye}
              size="lg"
              color="#868DA1"
              style={{ paddingRight: '4px' }}
            />
            <Text
              as="span"
              marginLeft={1}
              sizeText="display16"
              color="gray2"
              fontWeight="medium"
              style={{ cursor: 'pointer' }}
              onClick={() => setModalToOpen({ show: true, element })}>
              Ver declaraciones
            </Text>
          </Button>
        </Flex>
      </Flex>
      {isOpened && (
        <>
          {element?.itemDeclarations?.map((declaration) => (
            <Grid
              key={declaration?.proposalItemId}
              gridTemplateAreas={
                "'proposalItem unit offered executed accumulated unitaryPrice executedPrice kg total kgRecycledTotal'"
              }
              gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
              paddingY={5}
              width="100%"
              borderBottomColor="gray4"
              borderBottomStyle="solid"
              borderBottomWidth="1px">
              <GridItem gridArea="proposalItem" display="flex" style={{ alignItems: 'flex-start' }}>
                <Text
                  sizeText="display14"
                  fontWeight="medium"
                  color="gray1"
                  paddingX={2}
                  paddingLeft={8}>
                  {declaration.name}
                </Text>
              </GridItem>
              <GridItem gridArea="unit" display="flex" style={{ alignItems: 'flex-start' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {declaration.unit}
                </Text>
              </GridItem>
              <GridItem
                gridArea="offered"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.measurement, 3)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="executed"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.declaredAmount, 3)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="accumulated"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.accumulatedAmount, 3)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="unitaryPrice"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.unitaryPrice, 2)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="executedPrice"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.executedBudget, 2)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="kg"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.unitaryECo2, 2)}
                </Text>
              </GridItem>
              <GridItem
                gridArea="total"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  <Flex>
                    {declaration.accumulatedAmount !== 0 &&
                      declaration.measurement !== 0 &&
                      declaration.accumulatedAmount / declaration.measurement > 0.8 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          size="lg"
                          color="#D9993B"
                          style={{ paddingRight: '4px' }}
                        />
                      )}{' '}
                    {parseToString(declaration.accumulatedECo2, 2)}
                  </Flex>
                </Text>
              </GridItem>
              <GridItem
                gridArea="kgRecycledTotal"
                display="flex"
                style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text sizeText="display12" color="gray1" paddingX={2}>
                  {parseToString(declaration.accumulatedAmountRecycled, 3)}
                </Text>
              </GridItem>
            </Grid>
          ))}
          <Grid
            gridTemplateAreas={
              "'proposalItem unit offered executed accumulated unitaryPrice executedPrice kg total kgRecycledTotal'"
            }
            gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
            paddingY={5}
            width="100%"
            borderBottomColor="gray4"
            borderBottomStyle="solid"
            borderBottomWidth="1px">
            <GridItem gridArea="proposalItem" display="flex" style={{ alignItems: 'flex-start' }}>
              <Text
                sizeText="display14"
                fontWeight="medium"
                color="gray1"
                paddingX={2}
                paddingLeft={8}>
                Total importe mensual
              </Text>
            </GridItem>
            <GridItem
              gridArea="unit"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="offered"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="executed"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="accumulated"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="unitaryPrice"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="executedPrice"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display14" color="gray1" fontWeight="medium" paddingX={2}>
                {parseToString(
                  element?.itemDeclarations?.reduce(
                    (acc, declaration) => acc + declaration.executedBudget,
                    0,
                  ),
                  2,
                )}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kg"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="total"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
            <GridItem
              gridArea="kgRecycledTotal"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}
            />
          </Grid>
        </>
      )}
      <ModalDetailProjectExecution
        isOpened={modalToOpen.show}
        handleClose={handleClose}
        element={element}
      />
    </>
  )
}
