import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { DetailProposalExecution } from './DetailProposalExecution'
import { useEnviro } from '../../../../common/hooks/useEnviro'
import { ModalDeclaration } from './ModalDeclaration'
import { parseToString } from '../../../../../lib/utils/Format'

export const DetailProposalExecutions = ({ proposalId, proposalItems }) => {
  const [modalToOpen, setModalToOpen] = useState({ show: false, element: {} })
  const {
    data: { declarations },
    getDeclarations,
    deleteDeclaration,
  } = useEnviro()

  useEffect(() => {
    const getData = async () => {
      await getDeclarations(proposalId)
    }
    if (proposalId) {
      getData()
    }
  }, [proposalId])

  const handleClose = () => setModalToOpen({ show: false, element: {} })

  const initialValues = {
    declarationId: uuidv4(),
    proposalId,
    itemDeclarations: proposalItems.map((item) => ({
      proposalItemId: item.proposalItemId,
      name: item.bidItem.name,
      unit: item.bidItem.unit,
      measurement: parseToString(item.bidItem.measurement, 3),
    })),
  }

  return (
    <>
      {!declarations?.length && (
        <Flex marginTop={4} marginBottom={6} flexWrap="wrap">
          <Text sizeText="display12" color="gray2" overflow="hidden">
            No hay ninguna declaración mensual. Haz clic en &quot;Añadir declaración&quot; para
            añadir una
          </Text>
        </Flex>
      )}

      <Box mb={6}>
        <Button
          colorType="transparent"
          action={() => setModalToOpen({ show: true, element: initialValues })}
          width="178px">
          Añadir declaración
        </Button>
      </Box>

      {declarations?.length > 0 && (
        <>
          <Grid
            gridTemplateAreas={
              "'proposalItem unit offered executed accumulated kg total percentageRecycled kgRecycled kgRecycledTotal'"
            }
            gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
            bg="gray4"
            width="100%"
            paddingY={4}
            borderRadius={2}>
            <GridItem gridArea="proposalItem">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Partida
              </Text>
            </GridItem>
            <GridItem gridArea="unit">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Unidades
              </Text>
            </GridItem>
            <GridItem gridArea="offered">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Cantidad ofertada
              </Text>
            </GridItem>
            <GridItem gridArea="executed">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Cantidad ejecutada
              </Text>
            </GridItem>
            <GridItem gridArea="accumulated">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Cantidad acumulada
              </Text>
            </GridItem>
            <GridItem gridArea="kg">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                kg CO2 eq/Ud
              </Text>
            </GridItem>
            <GridItem gridArea="total">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Total kg CO2 eq
              </Text>
            </GridItem>
            <GridItem gridArea="percentageRecycled">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                % mat. reciclados
              </Text>
            </GridItem>
            <GridItem gridArea="kgRecycled">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                kg reciclados
              </Text>
            </GridItem>
            <GridItem gridArea="kgRecycledTotal">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                kg rec. total
              </Text>
            </GridItem>
          </Grid>
          {declarations?.map((element) => (
            <DetailProposalExecution element={element?.resource} setModalToOpen={setModalToOpen} />
          ))}
        </>
      )}
      <ModalDeclaration
        proposalId={proposalId}
        isOpened={modalToOpen.show}
        handleClose={handleClose}
        element={modalToOpen.element}
        deleteDeclaration={deleteDeclaration}
        getDeclarations={getDeclarations}
      />
    </>
  )
}
