export const checkValidationSubmit = (data) => {
  if (
    !!data?.name &&
    !!data?.address &&
    !!data?.city &&
    !!data?.postCode &&
    !!data?.start &&
    !!data?.end &&
    !!data?.constructionType &&
    !!data?.projectType
  ) {
    return true
  }
  return false
}

export const errorsImages = [
  {
    type: 'size',
    message: (fileNames) =>
      `Algunos documentos superan el límite máximo permitido por archivo (hasta 10mb por archivo): ${fileNames}`,
  },
  {
    type: 'wrongType',
    message: (fileNames) =>
      `Algunos documento tienen un formato de archivo no permitido: ${fileNames}`,
  },
  {
    type: 'numFiles',
    message: (numFiles) => `Has arrastrado más de ${numFiles} archivos en un único intento.`,
  },
]

export const copyState = {
  Draft: 'Borrador',
  Published: 'Publicada',
  Awarded: 'Seleccionada',
  Cancelled: 'Cancelada',
  Open: 'En curso',
  Closed: 'Finalizada',
  'Awaiting response': 'Propuesta recibida',
  'Awaiting improvement': 'Mensaje enviado',
  'Cancelled by buyer': 'Compra cancelada',
  'Finished awarded': 'Seleccionada',
  'Finished rejected': 'Descartada',
  Rejected: 'No seleccionada',
  'Not awarded': 'No seleccionada',
}

export const colorState = {
  Draft: 'gray3',
  Published: 'blue2',
  Awarded: 'green1',
  Cancelled: 'red1',
  Open: 'green1',
  Closed: 'red1',
  'Awaiting response': 'blue2',
  'Awaiting improvement': 'orange2',
  'Cancelled by buyer': 'red2',
  'Finished awarded': 'green3',
  'Finished rejected': 'gray4',
  Rejected: 'gray4',
  'Not awarded': 'red2',
}

export const offerStatusCopy = {
  Invited: 'Invitado',
  Pending: 'Enviado',
  Accepted: 'Aceptado',
  Cancelled: 'Cancelado',
  Finished: 'Cerrado',
  Rejected: 'Rechazado',
  Awarded: 'Seleccionado',
}

export const paymentDaysOptions = [
  { value: '0', label: '0', iconLeft: null },
  { value: '30', label: '30', iconLeft: null },
  { value: '40', label: '40', iconLeft: null },
  { value: '50', label: '50', iconLeft: null },
  { value: '60', label: '60', iconLeft: null },
  { value: '90', label: '90', iconLeft: null },
  { value: '120', label: '120', iconLeft: null },
  { value: '150', label: '150', iconLeft: null },
  { value: '180', label: '180', iconLeft: null },
  { value: '210', label: '210', iconLeft: null },
  { value: '240', label: '240', iconLeft: null },
]
