import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Uploader } from '@proveoeng/uikit/dist/atoms/Uploader'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { onImagesLoad } from '../../../../common/functions/data'
import { useProject } from '../../../../common/hooks/useProject'
import { FormatDate } from '../../../../common/mappers/FormatDate'

export const ModalProjectWasteManagementDeclaration = ({
  isOpened = false,
  handleClose,
  data = [],
  projectId,
  planificationData,
}) => {
  const initialValues = {
    amount: data?.amount ? parseToString(data?.amount, 3) : '',
    departedAt: data?.departedAt ? FormatDate.hydrate(data?.departedAt).readableDate : '',
    plannedTreatment: data?.treatmentId
      ? [
          {
            label: data?.treatmentName,
            value: data?.treatmentId,
          },
        ]
      : '',
    files: data?.files || [],
  }
  const {
    data: { plannedTreatments },
    getPlannedTreatments,
    getWasteDeclarations,
    addWasteDeclaration,
    editWasteDeclaration,
    setDeclarationFiles,
    deleteDeclarationFiles,
  } = useProject()
  const [plannedTreatmentOptions, setPlannedTreatmentOptions] = useState([])

  const handleAddDeclaration = async () => {
    const body = {
      planItemId: planificationData?.planItemId,
      treatmentId: Array.isArray(values?.plannedTreatment)
        ? values?.plannedTreatment[0]?.value
        : values?.plannedTreatment,
      amount: parseToInteger(values?.amount, 3),
      departedAt: values?.departedAt,
    }

    const declarationId = uuidv4()
    await addWasteDeclaration(projectId, declarationId, body)
    await setDeclarationFiles(projectId, declarationId, files)
    await getWasteDeclarations(projectId)
    handleClose()
  }

  const handleEditDeclaration = async () => {
    const body = {
      planItemId: planificationData?.planItemId,
      treatmentId: Array.isArray(values?.plannedTreatment)
        ? values?.plannedTreatment[0]?.value
        : values?.plannedTreatment,
      amount: parseToInteger(values?.amount, 3),
      departedAt: values?.departedAt,
    }

    const filesToDelete = data?.files?.filter(
      (file) => !files.some((f) => f.fileId === file.fileId),
    )
    const filesToAdd = files.filter((file) => !data?.files?.some((f) => f.fileId === file.fileId))

    await editWasteDeclaration(projectId, data?.declarationId, body)
    await setDeclarationFiles(projectId, data?.declarationId, filesToAdd)
    await deleteDeclarationFiles(projectId, data?.declarationId, filesToDelete)
    await getWasteDeclarations(projectId)

    handleClose()
  }

  const { values, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit: data?.createdAt ? handleEditDeclaration : handleAddDeclaration,
    enableReinitialize: true,
  })
  const [files, setFiles] = useState([])

  const onDeleteFile = (file) => {
    const updateFiles = file?.fileId
      ? files.filter((element) => element.fileId !== file.fileId)
      : files.filter((element) => element.name !== file.name)
    setFiles(updateFiles)
  }

  useEffect(() => {
    const loadPlannedTreatments = async () => {
      await getPlannedTreatments()
    }
    loadPlannedTreatments()
  }, [])

  useEffect(() => {
    if (data?.files) {
      setFiles(data?.files)
    } else {
      setFiles([])
    }
  }, [data])

  useEffect(() => {
    if (planificationData?.materialLevel) {
      setPlannedTreatmentOptions(
        plannedTreatments?.data
          ?.filter((option) => option?.resource?.wasteLevel === planificationData?.materialLevel)
          .map((option) => ({
            label: option?.resource?.name,
            value: option?.resource?.treatmentId,
          })),
      )
    }
  }, [plannedTreatments?.data, planificationData?.materialLevel])

  useEffect(() => {
    resetForm()
  }, [data])

  return (
    <Modal
      isOpen={isOpened}
      id="declaration-modal"
      onModalClose={handleClose}
      title="Añadir declaración"
      closeWithButton
      width="496px">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Modal.Content>
          <Field required label="Generado (kg)" sizeText="display14" marginBottom={3}>
            <InputNumber
              required
              name="amount"
              placeholderMessage="0,000"
              ariaLabel="amount"
              value={values?.amount}
              onChange={handleChange}
              numeralDecimalScale={3}
            />
          </Field>
          <Field label="Fecha salida" required sizeText="display14" marginBottom={3}>
            <Input
              required
              type="date"
              name="departedAt"
              ariaLabel="departedAt"
              placeholderMessage="Fecha límite para presentar oferta"
              value={values?.departedAt}
              onChange={handleChange}
              min={new Date().toISOString().split('T')[0]}
            />
          </Field>
          <Field label="Tratamiento final" required sizeText="display14" marginBottom={3}>
            <SelectInput
              required
              isSearchable
              options={plannedTreatmentOptions}
              id="plannedTreatment"
              onChange={(_, v) => setFieldValue('plannedTreatment', v.value)}
              defaultValue={values?.plannedTreatment}
            />
          </Field>
          <Field label="Certificado" sizeText="display14" marginBottom={3}>
            <AttachmentList withDelete files={files} withDownload onDeleteFile={onDeleteFile} />
            <Uploader
              multiple
              name="files"
              marginBottom={3}
              onImagesLoad={(imgs) => onImagesLoad(imgs, setFiles, files)}
              descriptionMessage="Arrastra, o haz click para subir tus documentos aquí"
              accept="all"
              padding={5}
            />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateRows="1fr" paddingRight={5}>
              <Button colorType="transparent" fullWidth action={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid gridTemplateRows="1fr">
              <Button colorType="orange" fullWidth type="submit">
                {data?.createdAt ? 'Guardar' : 'Confirmar'}
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </form>
    </Modal>
  )
}
