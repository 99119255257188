import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Box } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button/Button'

import { deleteLocalStorage } from '../../functions/cookies'
import ecomaLogo from '../../assets/ECOMA_LOGO.png'

function useOutsideClick(ref, action) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const Sidebar = ({ placeholder, src, employee, menuItems }) => {
  const [isHover, setIsHover] = useState('')
  const history = useHistory()

  const onMouseEnterHover = (e) => setIsHover(e.target.getAttribute('name'))
  const onMouseOutHover = () => setIsHover('')
  const [showActions, setShowActions] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setShowActions(false))

  const profileActions = [
    {
      id: 'profile',
      content: (
        <Text sizeText="display14" color="black" style={{ cursor: 'pointer' }}>
          Tu perfil
        </Text>
      ),
      action: () => {
        const route = employee.type === 'Seller' ? '/perfil/proveedor' : '/perfil'
        history.push(route)
      },
    },
    ...(employee.type === 'Seller'
      ? []
      : [
          {
            id: 'company',
            content: (
              <Text sizeText="display14" color="black" style={{ cursor: 'pointer' }}>
                Tu compañía
              </Text>
            ),
            action: () => {
              history.push('/perfil/compania')
            },
          },
        ]),

    // {
    //   id: 'settings',
    //   content: (
    //     <Text sizeText="display14" color="black" style={{ cursor: 'pointer' }}>
    //       Ajustes
    //     </Text>
    //   ),
    //   action: () => alert('Ajustes'),
    // },
    {
      id: 'logout',
      content: (
        <Text sizeText="display14" color="red1" style={{ cursor: 'pointer' }}>
          Cerrar sesión
        </Text>
      ),
      action: () => {
        deleteLocalStorage(
          process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME,
        )
        history.push('/login')
      },
    },
  ]

  return (
    <Flex
      paddingY={5}
      paddingLeft={2}
      paddingRight={5}
      height="100vh"
      width="100%"
      maxWidth="214px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      style={{ zIndex: '3', maxHeight: '100vh' }}>
      <Flex flexDirection="column">
        <Flex display="flex" width="100%">
          <Flex
            alignItems="center"
            padding={4}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              // eslint-disable-next-line no-nested-ternary
              employee?.type === 'Buyer'
                ? history.push('/constructora/proyecto/')
                : employee?.type === 'Seller' && history.push('/proveedor/compras-nuevas')
            }>
            <img
              src={ecomaLogo}
              alt="ecoma logo"
              height="56px"
              width="auto"
              style={{ objectFit: 'fill' }}
            />
          </Flex>
        </Flex>
        {menuItems?.map((item) => {
          const { isActive, children, action, id, type } = item
          if (type === 'title') {
            return (
              <Flex
                key={`menuItems-title-${id}`}
                alignItems="flex-start"
                justifyContent="center"
                width="100%"
                flexDirection="column"
                backgroundColor="transparent"
                name={id}
                marginBottom={2}>
                <Text
                  sizeText="display16"
                  color="black"
                  paddingX="8px"
                  style={{
                    borderRadius: '8px',
                    whiteSpace: 'nowrap',
                    pointerEvents: 'none',
                  }}>
                  {children}
                </Text>
              </Flex>
            )
          }
          return (
            <Flex
              key={`menuItems-content-${id}`}
              alignItems="flex-start"
              justifyContent="center"
              width="100%"
              flexDirection="column"
              backgroundColor={isHover === id || isActive ? 'blue5' : 'transparent'}
              style={{
                cursor: 'pointer',
                borderRadius: '8px',
              }}
              onClick={action}
              padding={4}
              onMouseOver={(e) => onMouseEnterHover(e)}
              onMouseOut={() => onMouseOutHover()}
              name={id}
              marginBottom={2}>
              <Text
                sizeText="display16"
                color="black"
                paddingX="8px"
                style={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  whiteSpace: 'nowrap',
                  pointerEvents: 'none',
                }}>
                {children}
              </Text>
            </Flex>
          )
        })}
      </Flex>
      <Flex
        ref={wrapperRef}
        alignItems="center"
        justifyContent="center"
        width="100%"
        backgroundColor={isHover === 'your_account' || showActions ? 'blue5' : 'transparent'}
        style={{
          cursor: 'pointer',
          borderRadius: '8px',
        }}
        onClick={() => {
          setShowActions(true)
          if (showActions) setShowActions(false)
        }}
        padding={4}
        onMouseOver={(e) => onMouseEnterHover(e)}
        onMouseOut={() => onMouseOutHover()}
        name="your_account">
        <Avatar src={src} placeholder={placeholder} style={{ pointerEvents: 'none' }} />
        <Text
          sizeText="display16"
          color="black"
          paddingLeft="8px"
          fontWeight="medium"
          style={{ pointerEvents: 'none' }}>
          Tu cuenta
        </Text>
        {showActions && (
          <Box position="relative" backgroundColor="white" style={{ 'z-index': 99 }}>
            <Box
              position="absolute"
              boxShadow="bottom"
              borderRadius={1}
              minWidth="208px"
              backgroundColor="white"
              padding={0}
              margin={0}
              bottom="-30px"
              left="30px">
              {profileActions?.map((action) => {
                if (employee?.employeeRole !== 'Super' && action.id === 'company') return null

                return (
                  <>
                    <Button
                      sizeButton="none"
                      colorType="none"
                      paddingBottom={1}
                      padding={4}
                      disabled={action?.isDisabled}
                      action={(e) => {
                        e.preventDefault()
                        action?.action()
                      }}
                      width="100%"
                      justifyContent="flex-start">
                      {action?.content}
                    </Button>
                    <hr className="solid" style={{ border: '1px solid #EDECF0', margin: 0 }} />
                  </>
                )
              })}
            </Box>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

Sidebar.propTypes = {
  placeholder: PropTypes.string,
  src: PropTypes.string,
}
Sidebar.defaultProps = {
  placeholder: null,
  src: null,
}
