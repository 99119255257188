import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Box, Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'

export const ModalDeleteDeclaration = ({ isOpened, handleClose, handleDeleteDeclaration }) => (
  <Modal
    isOpen={isOpened}
    id="delete-declaration-modal"
    onModalClose={handleClose}
    minWidth="300"
    width="390px"
    paddingTop={6}>
    <Modal.Content>
      <Box>
        <Text sizeText="display14">¿Seguro que quieres eliminar esta declaración?</Text>
      </Box>
    </Modal.Content>
    <Modal.Actions>
      <Flex justifyContent="flex-end">
        <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={5}>
          <Button colorType="orange" action={handleDeleteDeclaration}>
            Si
          </Button>
          <Button id="cancel-button" colorType="transparent" action={handleClose}>
            No
          </Button>
        </Grid>
      </Flex>
    </Modal.Actions>
  </Modal>
)
