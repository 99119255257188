import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight, PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToString } from '../../../../../../lib/utils/Format'
import { useProject } from '../../../../../common/hooks/useProject'
import { ModalProjectBuildingWasteManagementMaterial } from './ModalProjectBuildingWasteManagementMaterial'

export const ProjectBuildingWasteManagement = ({ project, goToWasteManagementDeclarations }) => {
  const [isModalOpened, setIsModalOpened] = useState({ show: false, data: [] })
  const [isOpened, setIsOpened] = useState(true)
  const handleModalClose = () => setIsModalOpened({ show: false, data: [] })
  const {
    data: { buildingWasteManagement },
    getBuildingWasteManagement,
  } = useProject()

  useEffect(() => {
    if (!isModalOpened.show && project.projectId) {
      getBuildingWasteManagement(project.projectId)
    }
  }, [project.projectId, isModalOpened.show])

  return (
    <>
      <Flex paddingBottom={4}>
        <Icon
          style={{ cursor: 'pointer' }}
          sizeIcon="display18"
          color="black"
          marginRight={2}
          onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? <ArrowDown /> : <ArrowRight />}
        </Icon>
        <Text
          sizeText="display16"
          fontWeight="medium"
          pr={4}
          onClick={() => setIsOpened(!isOpened)}
          style={{ cursor: 'pointer' }}>
          Obra
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <PlusCircle sizeIcon="display18" />
          <Text
            color="gray1"
            sizeText="display14"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setIsModalOpened({ show: true, data: [] })}>
            Añadir material
          </Text>
        </Flex>
      </Flex>
      {isOpened && (
        <>
          <Grid
            gridTemplateAreas={
              "'code material rcd type category accumulation egr plannedTreatment manager seeDeclarations'"
            }
            gridTemplateColumns="100px 1fr 90px 100px 80px 70px 90px 130px 130px 180px"
            bg="gray4"
            width="100%"
            paddingY={4}
            borderRadius={2}>
            <GridItem gridArea="code" paddingX={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Código residuo
              </Text>
            </GridItem>
            <GridItem gridArea="material" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Material
              </Text>
            </GridItem>
            <GridItem gridArea="rcd" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Tipo RCD
              </Text>
            </GridItem>
            <GridItem gridArea="type" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Tipo material
              </Text>
            </GridItem>
            <GridItem gridArea="category" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Naturaleza
              </Text>
            </GridItem>
            <GridItem gridArea="accumulation" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Acopio separado
              </Text>
            </GridItem>
            <GridItem gridArea="egr" paddingRight={4}>
              <Text
                sizeText="display12"
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Previsto EGR (tn)
              </Text>
            </GridItem>
            <GridItem gridArea="plannedTreatment" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Tratamiento previsto
              </Text>
            </GridItem>
            <GridItem gridArea="manager" paddingRight={4}>
              <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                Gestor
              </Text>
            </GridItem>
            <GridItem gridArea="seeDeclarations" />
          </Grid>
          {buildingWasteManagement?.data?.length === 0 && (
            <Flex
              paddingY={3}
              flexWrap="wrap"
              paddingLeft={4}
              borderBottomColor="gray4"
              borderBottomStyle="solid"
              borderBottomWidth="1px">
              <Text sizeText="display12" color="gray2" overflow="hidden">
                No hay ningún material en el plan. Haz clic en &quot;Añadir material&quot; para
                añadir uno
              </Text>
            </Flex>
          )}
          {buildingWasteManagement?.data?.length > 0 &&
            buildingWasteManagement?.data?.map((material) => (
              <Grid
                gridTemplateAreas={
                  "'code material rcd type category accumulation egr plannedTreatment manager seeDeclarations'"
                }
                gridTemplateColumns="100px 1fr 90px 100px 80px 70px 90px 130px 130px 180px"
                paddingY={3}
                width="100%"
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem gridArea="code" paddingX={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.materialCode}
                  </Text>
                </GridItem>
                <GridItem gridArea="material" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.materialName}
                  </Text>
                </GridItem>
                <GridItem gridArea="rcd" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.materialLevel}
                  </Text>
                </GridItem>
                <GridItem gridArea="type" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.materialType}
                  </Text>
                </GridItem>
                <GridItem gridArea="category" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.materialKind}
                  </Text>
                </GridItem>
                <GridItem gridArea="accumulation" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.separateCollection ? 'Sí' : 'No'}
                  </Text>
                </GridItem>
                <GridItem gridArea="egr" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden" textAlign="right">
                    {parseToString(material?.resource?.expectedAmount / 1000, 3)}
                  </Text>
                </GridItem>
                <GridItem gridArea="plannedTreatment" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.treatmentName}
                  </Text>
                </GridItem>
                <GridItem gridArea="manager" paddingRight={4}>
                  <Text sizeText="display14" color="gray1" overflow="hidden">
                    {material?.resource?.wasteManagerCompanyName}
                  </Text>
                </GridItem>
                <GridItem gridArea="seeDeclarations">
                  <Button
                    colorType="none"
                    sizeButton="none"
                    action={() =>
                      goToWasteManagementDeclarations(material?.resource?.planItemId, 'obra')
                    }>
                    <FontAwesomeIcon
                      icon={faEye}
                      size="lg"
                      color="#868DA1"
                      style={{ paddingRight: '4px' }}
                    />
                    <Text
                      as="span"
                      marginLeft={1}
                      sizeText="display14"
                      color="gray2"
                      fontWeight="medium"
                      style={{ cursor: 'pointer' }}>
                      Ver declaraciones
                    </Text>
                  </Button>
                </GridItem>
              </Grid>
            ))}
        </>
      )}

      <ModalProjectBuildingWasteManagementMaterial
        isOpened={isModalOpened.show}
        handleClose={handleModalClose}
        data={isModalOpened?.data}
        projectId={project.projectId}
      />
    </>
  )
}
