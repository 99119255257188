import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Input } from '@proveoeng/uikit/dist/atoms/Input/Input'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { useEnviro } from '../../../../common/hooks/useEnviro'
import { FormatDate } from '../../../../common/mappers/FormatDate'

export const ModalTransport = ({ proposalId, isOpened = false, handleClose, data = [] }) => {
  const initialValues = {
    materialName: data?.materialName ? data?.materialName : '',
    transportedAt: data?.transportedAt ? FormatDate.hydrate(data?.transportedAt).readableDate : '',
    vehicle: data?.vehicleId
      ? [
          {
            label: data?.vehicleName,
            value: data?.vehicleId,
          },
        ]
      : '',
    distance: data?.distance ? parseToString(data?.distance, 2) : '',
    weight: data?.weight ? parseToString(data?.weight, 2) : '',
    vol: '',
    dens: '',
  }
  const [transportVehiclesOptions, setTransportVehiclesOptions] = useState([])
  const {
    data: { transportVehicles },
    addTransportDeclaration,
    editTransportDeclaration,
    getTransportVehicles,
  } = useEnviro()

  const handleAddTransportDeclaration = async () => {
    const body = {
      materialName: values?.materialName,
      transportedAt: data?.transportedAt
        ? FormatDate.hydrate(values?.transportedAt).readableDate
        : '',
      vehicleId: Array.isArray(values?.vehicle) ? values?.vehicle[0]?.value : values?.vehicle,
      distance: parseToInteger(values?.distance, 2),
      weight:
        values?.vol || values?.dens
          ? parseToInteger(values?.vol, 2) * parseToInteger(values?.dens, 2)
          : parseToInteger(values?.weight, 2),
    }

    const declarationId = uuidv4()
    await addTransportDeclaration(proposalId, declarationId, body)
    handleClose()
  }

  const handleEditBuildingWasteManagement = async () => {
    const body = {
      materialName: values?.materialName,
      transportedAt: data?.transportedAt
        ? FormatDate.hydrate(values?.transportedAt).readableDate
        : '',
      vehicleId: Array.isArray(values?.vehicle) ? values?.vehicle[0]?.value : values?.vehicle,
      distance: parseToInteger(values?.distance, 2),
      weight:
        values?.vol || values?.dens
          ? parseToInteger(values?.vol, 2) * parseToInteger(values?.dens, 2)
          : parseToInteger(values?.weight, 2),
    }

    await editTransportDeclaration(proposalId, data?.declarationId, body)
    handleClose()
  }

  const validationSchema = Yup.object().shape({
    materialName: Yup.string().required('Debe ingresar un material'),
    transportedAt: Yup.string().required('Debe ingresar una fecha'),
    vehicle: Yup.string().required('Debe ingresar un vehículo'),
    distance: Yup.number().required('Debe ingresar una distancia'),
  })

  const { values, handleSubmit, handleChange, touched, setFieldValue, resetForm, errors } =
    useFormik({
      initialValues,
      onSubmit: data?.declaredAt
        ? handleEditBuildingWasteManagement
        : handleAddTransportDeclaration,
      enableReinitialize: true,
      validationSchema,
    })

  useEffect(() => {
    const loadTransportVehicles = async () => {
      await getTransportVehicles()
    }

    loadTransportVehicles()
  }, [])

  useEffect(() => {
    if (transportVehicles) {
      setTransportVehiclesOptions(
        transportVehicles?.map((option) => ({
          label: option?.resource?.name,
          value: option?.resource?.vehicleId,
        })),
      )
    }
  }, [transportVehicles])

  useEffect(() => {
    resetForm()
  }, [isOpened])

  return (
    <Modal
      isOpen={isOpened}
      id="declaration-Building-modal"
      onModalClose={handleClose}
      title="Declaración de gestión de residuos"
      closeWithButton
      width="544px">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Modal.Content>
          <Flex
            flexDirection="column"
            style={{ width: '100%', overflow: 'auto', maxHeight: '500px' }}>
            <Field required label="Material" sizeText="display14" marginBottom={3}>
              <Input
                required
                name="materialName"
                value={values?.materialName}
                onChange={handleChange}
                hasError={touched.materialName && !!errors?.materialName}
              />
            </Field>
            <Field required label="Fecha de transporte" sizeText="display14" marginBottom={3}>
              <Input
                required
                type="date"
                name="transportedAt"
                ariaLabel="transportedAt"
                placeholderMessage="Fecha de transporte"
                value={values?.transportedAt}
                onChange={handleChange}
                min={new Date().toISOString().split('T')[0]}
                hasError={touched.transportedAt && !!errors?.transportedAt}
              />
            </Field>
            <Field required label="Tipo de vehículo" sizeText="display14" marginBottom={3}>
              <SelectInput
                required
                isSearchable
                options={transportVehiclesOptions}
                id="vehicle"
                onChange={(_, v) => setFieldValue('vehicle', v.value)}
                defaultValue={values?.vehicle}
                hasError={touched.vehicle && !!errors?.vehicle}
              />
            </Field>
            <Field required label="Kilómetros" sizeText="display14" marginBottom={3}>
              <InputNumber
                required
                name="distance"
                placeholderMessage="0,00"
                ariaLabel="distance"
                value={values?.distance}
                onChange={handleChange}
                numeralDecimalScale={2}
                hasError={touched.distance && !!errors?.distance}
              />
            </Field>
            <Text sizeText="display14" fontWeight="medium" paddingTop={5}>
              Puede introducir el volumen y la densidad para calcular el peso transportado o
              directamente ingresar el peso
            </Text>
            <Flex justifyContent="space-between">
              <Flex flexDirection="column" width="49%">
                <Field label="Volumen (m3)" sizeText="display14" marginBottom={3}>
                  <InputNumber
                    name="vol"
                    placeholderMessage="0,00"
                    ariaLabel="vol"
                    value={values?.vol}
                    onChange={handleChange}
                    numeralDecimalScale={2}
                  />
                </Field>
              </Flex>
              <Flex flexDirection="column" width="49%">
                <Field label="Densidad kg/m3" sizeText="display14" marginBottom={3}>
                  <InputNumber
                    name="dens"
                    placeholderMessage="0,00"
                    ariaLabel="dens"
                    value={values?.dens}
                    onChange={handleChange}
                    numeralDecimalScale={2}
                  />
                </Field>
              </Flex>
            </Flex>
            <Field required label="Peso transportado (kg)" sizeText="display14" marginBottom={3}>
              <InputNumber
                required
                name="weight"
                disabled={values?.vol || values?.dens}
                placeholderMessage="0,00"
                ariaLabel="weight"
                value={
                  values?.vol || values?.dens
                    ? parseToString(
                        parseToInteger(parseToString(values?.vol)) *
                          parseToInteger(parseToString(values?.dens)),
                        4,
                      )
                    : values?.weight
                }
                onChange={handleChange}
                numeralDecimalScale={2}
                maxWidth="40%"
                hasError={touched.weight && !!errors?.weight}
              />
            </Field>
          </Flex>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateRows="1fr" paddingRight={5}>
              <Button colorType="transparent" fullWidth action={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid gridTemplateRows="1fr">
              <Button colorType="orange" fullWidth type="submit">
                Añadir declaración
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </form>
    </Modal>
  )
}
