import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { ProjectBuildingWasteManagement } from './Building/ProjectBuildingWasteManagement'
import { ProjectDemolitionWasteManagement } from './Demolition/ProjectDemolitionWasteManagement'

export const ProjectWasteManagement = ({ project, history }) => {
  const goToWasteManagementDeclarations = (planItemId, type) =>
    history.push(
      `/constructora/tus-obras/${project.projectId}/gestion-residuos/${planItemId}/declaraciones/${type}`,
    )
  const goBack = () => history.push(`/constructora/tus-obras/${project.projectId}`)

  return (
    <>
      <Box paddingBottom={6}>
        <ProjectDemolitionWasteManagement
          project={project}
          goToWasteManagementDeclarations={goToWasteManagementDeclarations}
          goBack={goBack}
        />
      </Box>
      <Box paddingBottom={6}>
        <ProjectBuildingWasteManagement
          project={project}
          goToWasteManagementDeclarations={goToWasteManagementDeclarations}
          goBack={goBack}
        />
      </Box>
    </>
  )
}
