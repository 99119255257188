import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { AttachmentList } from '../../../../common/components/attachmentList/Attachment'
import { Links } from '../../../../common/components/links/Links'

export const DetailEnvironmentalStatement = ({ proposal }) => (
  <Flex
    flexDirection="column"
    alignItems="baseline"
    paddingX={0}
    margin="0 auto"
    width="100%"
    height="100%">
    <Flex paddingBottom="40px" width="100%">
      <Flex minWidth="264px" maxWidth="264px" alignItems="center">
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Distancia a obra (km)
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <InputNumber
          required
          disabled
          name="distanceToBuildingSite"
          placeholderMessage="0,00"
          ariaLabel="distanceToBuildingSite"
          onChange={() => undefined}
          onBlur={() => undefined}
          value={proposal?.distanceToBuildingSite}
          hasError={false}
          numeralDecimalScale={3}
        />
      </Flex>
    </Flex>
    <Flex paddingBottom="40px" width="100%">
      <Flex minWidth="264px" maxWidth="264px" alignItems="center">
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Uso de materiales reciclados (kg)
        </Text>
      </Flex>
      <Flex paddingLeft={4}>
        <InputNumber
          required
          disabled
          name="recycledMaterials"
          placeholderMessage="0,00"
          ariaLabel="recycledMaterials"
          onChange={() => undefined}
          onBlur={() => undefined}
          value={proposal?.recycledMaterials}
          hasError={false}
          numeralDecimalScale={3}
        />
      </Flex>
    </Flex>
    <Flex paddingBottom="40px" width="100%">
      <Flex minWidth="264px">
        <Text as="span" sizeText="display18" color="black" fontWeight="600">
          Comentarios
        </Text>
      </Flex>
      <Flex paddingLeft={4} width="100%">
        <Textarea
          disabled
          placeholderMessage="Escribe aquí los comentarios"
          name="commentsEnviro"
          onChange={() => undefined}
          hasError={false}
          defaultValue={proposal?.commentsEnviro}
        />
      </Flex>
    </Flex>
    <Flex paddingBottom="40px" width="100%">
      <Flex minWidth="264px" maxWidth="264px" flexDirection="column">
        <Text as="span" sizeText="display18" color="black" fontWeight="600" paddingBottom={2}>
          Certificaciones
        </Text>
        <Text as="span" sizeText="display14" color="gray1">
          Puedes adjuntar ficheros o enlaces referentes a la declaración ambiental
        </Text>
      </Flex>
      <Flex flexDirection="column" marginBottom={7} paddingLeft={4} width="100%">
        <Flex alignItems="center" width="100%" paddingBottom={4}>
          <AttachmentList files={proposal?.filesEnviro} />
          <Links links={proposal?.linksEnviro} />
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)
