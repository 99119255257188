import { useEffect, useState } from 'react'

import { Box, Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { useEnviro } from '../../../../common/hooks/useEnviro'
import { DetailProposalTransport } from './DetailProposalTransport'
import { ModalTransport } from './ModalTransport'

export const DetailProposalTransports = ({ proposalId }) => {
  const [modalToOpen, setModalToOpen] = useState(false)
  const [declarationToEdit, setDeclarationToEdit] = useState(null)
  const {
    data: { declarations },
    getTransportDeclarations,
  } = useEnviro()

  useEffect(() => {
    const getData = async () => {
      await getTransportDeclarations(proposalId)
    }
    if (proposalId) {
      getData()
    }
  }, [proposalId])

  const handleClose = () => setModalToOpen(false)
  const handleEditDeclaration = (declaration) => {
    setDeclarationToEdit(declaration)
  }

  useEffect(() => {
    if (declarationToEdit) {
      setModalToOpen(true)
    }
  }, [declarationToEdit])

  useEffect(() => {
    if (!modalToOpen) {
      getTransportDeclarations(proposalId)
    }
  }, [modalToOpen])

  return (
    <>
      {!declarations?.length && (
        <Flex marginTop={4} marginBottom={6} flexWrap="wrap">
          <Text sizeText="display12" color="gray2" overflow="hidden">
            No hay ninguna gestión reclamada. Haz clic en &quot;Añadir gestión&quot; para añadir una
          </Text>
        </Flex>
      )}

      <Box mb={6}>
        <Button colorType="transparent" action={() => setModalToOpen(true)} width="178px">
          Añadir declaración
        </Button>
      </Box>

      {declarations?.length > 0 && (
        <>
          <Grid
            gridTemplateAreas={"'material date type km weight kgEmit'"}
            gridTemplateColumns="1fr 100px 145px 110px 110px 110px"
            bg="gray4"
            width="100%"
            paddingY={4}
            borderRadius={2}>
            <GridItem gridArea="material">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Material
              </Text>
            </GridItem>
            <GridItem gridArea="date">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Fecha de transporte
              </Text>
            </GridItem>
            <GridItem gridArea="type">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium">
                Tipo de vehículo
              </Text>
            </GridItem>
            <GridItem gridArea="km">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                km
              </Text>
            </GridItem>
            <GridItem gridArea="weight">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                Peso transp. (kg)
              </Text>
            </GridItem>
            <GridItem gridArea="kgEmit">
              <Text
                sizeText="display12"
                paddingX={4}
                color="gray1"
                overflow="hidden"
                fontWeight="medium"
                textAlign="right">
                kg CO2eq emitido
              </Text>
            </GridItem>
          </Grid>
          {declarations?.map((element) => (
            <DetailProposalTransport
              element={element?.resource}
              handleEditDeclaration={handleEditDeclaration}
            />
          ))}
        </>
      )}
      <ModalTransport
        proposalId={proposalId}
        isOpened={modalToOpen}
        handleClose={handleClose}
        data={declarationToEdit}
      />
    </>
  )
}
