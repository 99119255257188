import axios from 'axios'
import jwtDecode from 'jwt-decode'
import qs from 'qs'

import { deleteLocalStorage, getLocalStorage } from '../../cli/common/functions/cookies'
import { serializeObjectToFormData } from '../../cli/common/functions/serialize'
import { setErrorsAlerts, setErrorsInputs } from '../../cli/common/functions/data'

const apiBase = process?.env?.REACT_APP_URL_BASE || env?.REACT_APP_URL_BASE
export const apiUrl = `${apiBase}`

const getAuthorizationConfig = () => {
  let authorizationConfig = {}
  try {
    const apiToken = getLocalStorage(
      env.REACT_APP_COOKIE_LOGIN_NAME || process?.env?.REACT_APP_COOKIE_LOGIN_NAME,
    )
    const checkApiToken = jwtDecode(apiToken, { header: true })

    if (checkApiToken) {
      const tokenDecode = jwtDecode(apiToken)

      if (Date.now() >= tokenDecode.exp * 1000) throw new Error('El token ha expirado!')

      authorizationConfig = {
        Authorization: `Bearer ${apiToken}`,
      }
    }
  } catch (error) {
    deleteLocalStorage(process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME)
  }

  return authorizationConfig
}

const getResponseType = (responseType) => {
  if (responseType) {
    return {
      responseType: `${responseType}`,
    }
  }

  return {}
}

export const requestApi = (service, config) => {
  const newUrl = service.extern ? `${service.route}` : `${apiUrl}${service.route}`
  const contentType = service.formData
    ? { 'Content-Type': 'multipart/form-data' }
    : { Accept: 'application/json', 'Content-Type': 'application/json' }
  const configRequest = {
    ...config,
    method: service.method,
    credentials: 'same-origin',
    'Access-Control-Allow-Origin': '*',
    headers: {
      ...contentType,
      ...getAuthorizationConfig(),
      ...service.headers,
    },
    ...getResponseType(service?.responseType),
    url: newUrl,
  }

  if (service.method === 'post' || service.method === 'put' || service.method === 'patch') {
    configRequest.data = service.formData ? serializeObjectToFormData(service.body) : service.body
  }

  if (service.params) {
    configRequest.params = service.params
  }

  if (service.paramsSerializer) {
    configRequest.paramsSerializer = service.paramsSerializer
  }

  return axios(configRequest.url, configRequest)
    .then((response) => response)
    .catch((err) => {
      if (err?.message === 'canceled') throw err
      const {
        status,
        data: { error },
      } = err.response
      const mapError = {
        ...error,
        code: status,
        ...setErrorsAlerts(error?.inputErrors),
        keys: error?.inputErrors && setErrorsInputs(error?.inputErrors),
      }
      throw mapError
    })
}

/**
 *
 * @param {Param} param
 * @returns {{
 *  orderBy: string,
 *  filterBy: string,
 * }}
 */
export const getParams = ({ order, filters }) => {
  let newParams = {}
  if (order) {
    newParams = {
      ...newParams,
      orderBy: `${order.field}=${order.type}`,
    }
  }

  if (filters) {
    newParams = {
      ...newParams,
      filterBy: filters
        .map(({ value }) => `${value.field}[${value.contains}][]=${value.search}`)
        .join('&'),
      // IN CASE eq, gt, etc...
      // filterBy: filters.reduce(
      //   (acc, { value }) => `${acc}${value.field}[${value.contains}]=${value.search}|`,
      //   ''
      // ),
    }
  }

  return newParams
}

const configSerialize = {
  arrayFormat: 'brackets',
  encodeValuesOnly: true,
  delimiter: '|',
}

export const getParamsSerializer = (params) => {
  let newParams = params

  if (params.filterBy) {
    newParams = {
      ...newParams,
      filterBy: qs.stringify(params.filterBy, configSerialize),
    }
  }

  if (params.orderBy) {
    newParams = {
      ...newParams,
      orderBy: qs.stringify(params.orderBy, configSerialize),
    }
  }

  return qs.stringify(newParams, { arrayFormat: 'brackets', encodeValuesOnly: true })
}
