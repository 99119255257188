import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import ArrowDown from '@proveoeng/uikit/dist/atoms/Icons/ArrowDown'
import ArrowRight from '@proveoeng/uikit/dist/atoms/Icons/ArrowRight'
import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { FormatDate } from '../../../../common/mappers/FormatDate'
import { parseToString } from '../../../../../lib/utils/Format'

export const DetailProposalExecution = ({ element, setModalToOpen }) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <>
      <Flex alignItems="center" paddingX={3} paddingY={3} width="100%" bg="white2">
        <Icon
          style={{ cursor: 'pointer' }}
          sizeIcon="display18"
          color="black"
          marginRight={4}
          onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? <ArrowDown /> : <ArrowRight />}
        </Icon>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text
            as="span"
            sizeText="display16"
            color="black"
            fontWeight="medium"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsOpened(!isOpened)}>
            {`Declaración ${FormatDate.hydrate(element.declaredAt)?.readableReverseDate}`}
          </Text>
          <Button colorType="none" sizeButton="none" action={() => {}}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              color="#868DA1"
              style={{ paddingRight: '4px' }}
            />
            <Text
              as="span"
              marginLeft={1}
              sizeText="display16"
              color="gray2"
              fontWeight="medium"
              style={{ cursor: 'pointer' }}
              onClick={() => setModalToOpen({ show: true, element })}>
              Editar declaración
            </Text>
          </Button>
        </Flex>
      </Flex>
      {isOpened &&
        element?.itemDeclarations?.map((declaration) => (
          <Grid
            key={declaration?.proposalItemId}
            gridTemplateAreas={
              "'proposalItem unit offered executed accumulated kg total percentageRecycled kgRecycled kgRecycledTotal'"
            }
            gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
            paddingY={5}
            width="100%"
            borderBottomColor="gray4"
            borderBottomStyle="solid"
            borderBottomWidth="1px">
            <GridItem gridArea="proposalItem" display="flex" style={{ alignItems: 'flex-start' }}>
              <Text sizeText="display12" color="gray1" paddingX={4} paddingLeft={8}>
                {declaration.name}
              </Text>
            </GridItem>
            <GridItem gridArea="unit" display="flex" style={{ alignItems: 'flex-start' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {declaration.unit}
              </Text>
            </GridItem>
            <GridItem
              gridArea="offered"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.measurement, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="executed"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.declaredAmount, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="accumulated"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.accumulatedAmount, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kg"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.unitaryECo2, 2)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="total"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                <Flex>
                  {declaration.accumulatedAmount / declaration.measurement > 0.8 && (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      size="lg"
                      color="#D9993B"
                      style={{ paddingRight: '4px' }}
                    />
                  )}{' '}
                  {parseToString(declaration.accumulatedECo2, 2)}
                </Flex>
              </Text>
            </GridItem>
            <GridItem
              gridArea="percentageRecycled"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {!declaration.declaredAmountRecycled || !declaration.declaredAmount
                  ? '-'
                  : (
                      (declaration.declaredAmountRecycled / declaration.declaredAmount) *
                      100
                    ).toFixed(2)}
                %{' '}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kgRecycled"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.declaredAmountRecycled, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kgRecycledTotal"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(declaration.accumulatedAmountRecycled, 3)}
              </Text>
            </GridItem>
          </Grid>
        ))}
    </>
  )
}
