import { useState } from 'react'

import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../../common/mappers/FormatDate'

export const ProjectTransportDetails = ({ declaration }) => {
  const [isOpened, setIsOpened] = useState(true)

  const getEco2 = () =>
    parseToString(
      declaration?.declarations?.reduce((acc, el) => acc + el.eCo2, 0),
      2,
    )

  return (
    <>
      <Flex padding={4} flexDirection="column" bg="white2">
        <Flex>
          <Icon
            style={{ cursor: 'pointer' }}
            sizeIcon="display18"
            color="gray2"
            marginRight={2}
            onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? <ArrowDown /> : <ArrowRight />}
          </Icon>
          <Text
            sizeText="display16"
            fontWeight="medium"
            pr={4}
            onClick={() => setIsOpened(!isOpened)}
            style={{ cursor: 'pointer' }}>
            {`${declaration?.bidPackageName} (${declaration?.sellerName})`}
          </Text>
        </Flex>
      </Flex>
      {isOpened && (
        <>
          {declaration?.declarations?.map((el) => (
            <Grid
              gridTemplateAreas={"'material date type km weight kgEmit'"}
              gridTemplateColumns="1fr 100px 145px 110px 110px 110px"
              paddingY={5}
              paddingLeft={5}
              width="100%"
              borderBottomColor="gray4"
              borderBottomStyle="solid"
              borderBottomWidth="1px">
              <GridItem gridArea="material">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium">
                  {el.materialName}
                </Text>
              </GridItem>
              <GridItem gridArea="date">
                <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden">
                  {FormatDate.hydrate(el.transportedAt).readableReverseDate}
                </Text>
              </GridItem>
              <GridItem gridArea="type">
                <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden">
                  {el.vehicleName}
                </Text>
              </GridItem>
              <GridItem gridArea="km">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  textAlign="right">
                  {parseToString(el.distance, 2)}
                </Text>
              </GridItem>
              <GridItem gridArea="weight">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  textAlign="right">
                  {parseToString(el.weight, 2)}
                </Text>
              </GridItem>
              <GridItem gridArea="kgEmit">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  textAlign="right">
                  {parseToString(el.eCo2, 2)}
                </Text>
              </GridItem>
            </Grid>
          ))}
          <Flex justifyContent="space-between" paddingLeft={5}>
            <Text padding={4} color="black" fontWeight="medium" sizeText="display14">
              Total
            </Text>
            <Text padding={4} color="black" fontWeight="medium" sizeText="display14">
              {getEco2()} kg CO2eq
            </Text>
          </Flex>
        </>
      )}
    </>
  )
}
