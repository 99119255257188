import { useState } from 'react'
import { requestApi } from '../../../lib/request'
import { services } from '../schemes/enviro/config'
import { catalogMapper } from '../schemes/enviro/mapper'
import { useUtils } from './useUtils'

export const useEnviro = () => {
  const { setError, setLoading, setAlert } = useUtils()
  const [declarations, setDeclarations] = useState([])
  const [transportVehicles, setTransportVehicles] = useState([])
  const [catalog, setCatalog] = useState({
    data: [],
    meta: {},
  })

  const getCatalog = async (params = {}) => {
    setLoading(true)
    try {
      const {
        data: { data, meta },
      } = await requestApi(services.getCatalog(params))
      setCatalog({
        meta,
        data: data.map((element) => catalogMapper.hydrate(element.resource)),
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getDeclarations = async (proposalId) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getDeclarations(proposalId))
      setDeclarations(data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const addDeclaration = async (proposalId, declarationId, body) => {
    setLoading(true)
    try {
      await requestApi(services.addDeclaration(proposalId, declarationId, body))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const editDeclaration = async (proposalId, declarationId, body) => {
    setLoading(true)
    try {
      await requestApi(services.editDeclaration(proposalId, declarationId, body))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getProjectDeclarations = async (projectId) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getProjectDeclarations(projectId))
      setDeclarations(data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteDeclaration = async (proposalId, declarationId) => {
    setLoading(true)
    try {
      await requestApi(services.deleteDeclaration(proposalId, declarationId))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getTransportDeclarations = async (proposalId) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getTransportDeclarations(proposalId))
      setDeclarations(data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const addTransportDeclaration = async (proposalId, declarationId, body) => {
    setLoading(true)
    try {
      await requestApi(services.addTransportDeclaration(proposalId, declarationId, body))
      setAlert('success', 'Declaración añadida correctamente.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const editTransportDeclaration = async (proposalId, declarationId, body) => {
    setLoading(true)
    try {
      await requestApi(services.editTransportDeclaration(proposalId, declarationId, body))
      setAlert('success', 'Declaración editada correctamente.')
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const getTransportVehicles = async () => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getTransportVehicles())
      setTransportVehicles(data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    data: {
      catalog,
      declarations,
      transportVehicles,
    },
    getCatalog,
    getDeclarations,
    addDeclaration,
    editDeclaration,
    getProjectDeclarations,
    deleteDeclaration,
    getTransportDeclarations,
    addTransportDeclaration,
    editTransportDeclaration,
    getTransportVehicles,
  }
}
