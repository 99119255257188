import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

export const CreateProjectSpendTemplate = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) => (
  <>
    <Text as="span" sizeText="display32" color="black" marginBottom={3} fontWeight="bold">
      Introduce factores de consumo
    </Text>
    <Field
      label="Factor de emisión de energía eléctrica"
      sizeText="display16"
      marginBottom={3}
      marginLeft={2}
      errorMessage={touched.energyECo2 && errors.energyECo2}>
      <InputNumber
        ariaLabel="energyECo2"
        marginBottom={3}
        placeholderMessage="Escribe el factor de emisión de energía eléctrica"
        hasError={touched.energyECo2 && !!errors.energyECo2}
        onChange={handleChange}
        name="energyECo2"
        value={values.energyECo2}
        onBlur={handleBlur}
      />
    </Field>
    <Field
      label="Factor de emisión de gasoil"
      sizeText="display16"
      marginBottom={3}
      marginTop={5}
      marginLeft={2}
      errorMessage={touched.fuelECo2 && errors.fuelECo2}>
      <InputNumber
        ariaLabel="fuelECo2"
        marginBottom={3}
        placeholderMessage="Escribe el factor de emisión de gasoil"
        hasError={touched.fuelECo2 && !!errors.fuelECo2}
        onChange={handleChange}
        name="fuelECo2"
        value={values.fuelECo2}
        onBlur={handleBlur}
      />
    </Field>
  </>
)
