import Proptypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'

import ProjectPage from '../../../pages/buyer/project/container/ProjectPage'
import ProjectsListPage from '../../../pages/buyer/projectList/container/ProjectsListPage'

import CreateProjectPage from '../../../pages/buyer/createProject/container/CreateProjectPage'

import HomeDashboardPage from '../../../pages/buyer/dashboard/container/HomeDashboardPage'
import HomeListingsPage from '../../../pages/buyer/listing/container/HomeListingsPage'

import BidItemListPage from '../../../pages/buyer/bidPackage/bidItemList/container/BidItemListPage'
import BidPackageContainer from '../../../pages/buyer/bidPackage/bidPackageContainer/container/BidPackageContainer'
import BidPackageInfoPage from '../../../pages/buyer/bidPackage/bidPackageInfo/container/BidPackageInfoPage'
import GuestPage from '../../../pages/buyer/bidPackage/guests/container/GuestsPage'
import ProposalsPage from '../../../pages/buyer/bidPackage/proposals/container/ProposalsPage'
import HistoryPage from '../../../pages/buyer/bidPackage/history/container/HistoryPage'

import OfferDetailPage from '../../../pages/buyer/offer/container/OfferDetailPage'

import ComparatorPage from '../../../pages/comparator/container/ComparatorPage'

import { ErrorPage } from '../../../pages/errors/Error'

import { Layout } from '../../../common/components/Layout/Layout'
import Ceco2Page from '../../../pages/buyer/ceco2/container/Ceco2Page'

import { isProduction } from '../../../../lib/utils/isProduction'
import EmissionsCalculatorPage from '../../../pages/buyer/bidPackage/emissionsCalculator/container/EmissionsCalculatorPage'
import HomeProjectInfoPage from '../../../pages/buyer/projectInfo/container/HomeProjectInfoPage'
import { ProjectWasteManagementDeclarations } from '../../../pages/buyer/project/components/ProjectWasteManagementDeclarations'

export const routes = (buyerPath) => ({
  path: buyerPath,
  pages: {
    buyer: {
      dashboard: {
        path: `${buyerPath}`,
      },
      listings: {
        path: `${buyerPath}/listings`,
      },
      projectInfo: {
        path: `${buyerPath}/proyecto`,
      },
      createProject: {
        path: `${buyerPath}/crear-obra`,
        pathRoute: `${buyerPath}/crear-obra/:name`,
        editProject: {
          path: `${buyerPath}/crear-obra/:name/:hash`,
        },
      },
      bidPackages: {
        path: `${buyerPath}/tus-obras`,
        hashPath: `${buyerPath}/tus-obras/:hash`,
        bidPackage: {
          path: `${buyerPath}/tus-obras/:hash/:bidPackageId`,
          bidItems: `${buyerPath}/tus-obras/:hash/:bidPackageId/partidas`,
          guests: `${buyerPath}/tus-obras/:hash/:bidPackageId/invitados`,
          proposals: `${buyerPath}/tus-obras/:hash/:bidPackageId/propuestas`,
          history: `${buyerPath}/tus-obras/:hash/:bidPackageId/historial`,
          emissionsCalculator: `${buyerPath}/tus-obras/:hash/:bidPackageId/calculo-emisiones`,
        },
      },
      wasteManagement: {
        path: `${buyerPath}/tus-obras/:projectId/gestion-residuos/:planItemId/declaraciones/:type`,
      },
      offers: {
        path: `${buyerPath}/ofertas`,
        hashPath: `${buyerPath}/ofertas/:hash`,
        comparative: {
          path: `${buyerPath}/ofertas/:hash/:bidPackageId/comparativo`,
        },
        comparator: {
          // TODO: change path obra to tus-obras
          path: `${buyerPath}/tus-obras/:projectId/:bidPackageId/comparativo`,
          hashPath: `${buyerPath}/tus-obras/:projectId/:bidPackageId/comparativo/:hash`,
          snapshotPath: `${buyerPath}/tus-obras/:projectId/:bidPackageId/comparativo/:hash/v/:snapshotId`,
        },
        offerDetail: {
          path: `${buyerPath}/ofertas/:hash/:bidPackageId/oferta/:offerId`,
        },
        guests: {
          path: `${buyerPath}/ofertas/:hash/:bidPackageId/invitados`,
        },
      },
      error: {
        path: `${buyerPath}/error`,
        hashPath: `${buyerPath}/error/:error`,
      },
      ceco2: {
        path: `${buyerPath}/costes-ambientales`,
      },
    },
  },
})

const RouterWrapper = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
)

const BidPackageRouterWrapper = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <BidPackageContainer {...props} page={component} />
      </Layout>
    )}
  />
)

export const BuyerRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url)
  return (
    <Switch>
      {!isProduction() && (
        <RouterWrapper
          path={applicationRoutes.pages.buyer.dashboard.path}
          component={HomeDashboardPage}
          layout={Layout}
          exact
        />
      )}
      {!isProduction() && (
        <RouterWrapper
          path={applicationRoutes.pages.buyer.listings.path}
          component={HomeListingsPage}
          layout={Layout}
          exact
        />
      )}
      {!isProduction() && (
        <RouterWrapper
          path={applicationRoutes.pages.buyer.projectInfo.path}
          component={HomeProjectInfoPage}
          layout={Layout}
          exact
        />
      )}
      <Route
        path={applicationRoutes.pages.buyer.createProject.path}
        component={CreateProjectPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.buyer.createProject.pathRoute}
        component={CreateProjectPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.buyer.createProject.editProject.path}
        component={CreateProjectPage}
        exact
      />
      <RouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.hashPath}
        component={ProjectPage}
        layout={Layout}
        exact
      />
      <RouterWrapper
        path={[
          applicationRoutes.pages.buyer.bidPackages.path,
          applicationRoutes.pages.buyer.offers.path,
        ]}
        component={ProjectsListPage}
        layout={Layout}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.path}
        component={BidPackageInfoPage}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.bidItems}
        component={BidItemListPage}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.guests}
        component={GuestPage}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.proposals}
        component={ProposalsPage}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.history}
        component={HistoryPage}
        exact
      />
      <BidPackageRouterWrapper
        path={applicationRoutes.pages.buyer.bidPackages.bidPackage.emissionsCalculator}
        component={EmissionsCalculatorPage}
        exact
      />

      <Route
        path={[
          applicationRoutes.pages.buyer.offers.comparator.path,
          applicationRoutes.pages.buyer.offers.comparator.hashPath,
          applicationRoutes.pages.buyer.offers.comparator.snapshotPath,
        ]}
        component={ComparatorPage}
        exact
      />
      <RouterWrapper
        path={applicationRoutes.pages.buyer.offers.offerDetail.path}
        component={OfferDetailPage}
        layout={Layout}
        exact
      />

      <RouterWrapper
        path={applicationRoutes.pages.buyer.wasteManagement.path}
        component={ProjectWasteManagementDeclarations}
        layout={Layout}
        exact
      />

      <RouterWrapper
        path={applicationRoutes.pages.buyer.ceco2.path}
        component={Ceco2Page}
        layout={Layout}
        exact
      />
      <RouterWrapper
        path={[
          applicationRoutes.pages.buyer.error.path,
          applicationRoutes.pages.buyer.error.hashPath,
        ]}
        layout={Layout}
        component={ErrorPage}
        exact
      />

      <Redirect to={applicationRoutes.pages.buyer.bidPackages.path} />
    </Switch>
  )
}

BuyerRoutes.propTypes = { match: Proptypes.shape().isRequired }
