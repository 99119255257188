import { useState } from 'react'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { ButtonTabs } from '@proveoeng/uikit/dist/atoms/Button'
import { DetailProposalInfo } from './DetailProposalInfo'
import { DetailProposalConditions } from './DetailProposalConditions'
import { DetailProposalBidItems } from './DetailProposalBidItems'
import { DetailProposalCostEmission } from './DetailProposalCostEmission'
import { DetailEnvironmentalStatement } from './DetailEnvironmentalStatement'
import { DetailProposalExecutions } from './DetailProposalExecutions'
import { DetailProposalTransports } from './DetailProposalTransports'

export const DetailProposal = ({
  bidPackage,
  proposal,
  proposalItems,
  handleExportBidPackagePrices,
}) => {
  const [whoIsActive, setWhoIsActive] = useState('info')

  const buttonTabsItems = [
    {
      id: 'info',
      children: <>Información de la obra</>,
      isActive: whoIsActive === 'info',
      action: (e, target) => setWhoIsActive(target.id),
    },
    {
      id: 'conditions',
      children: <>Condiciones de la oferta</>,
      isActive: whoIsActive === 'conditions',
      action: (_, target) => setWhoIsActive(target.id),
    },
    {
      id: 'bidItems',
      children: <>Partidas</>,
      isActive: whoIsActive === 'bidItems',
      action: (_, target) => setWhoIsActive(target.id),
    },
    {
      id: 'emissionCost',
      children: <>Emisiones</>,
      isActive: whoIsActive === 'emissionCost',
      action: (_, target) => setWhoIsActive(target.id),
    },
    {
      id: 'environmentalStatement',
      children: <>Declaración ambiental</>,
      isActive: whoIsActive === 'environmentalStatement',
      action: (_, target) => setWhoIsActive(target.id),
    },
    {
      id: 'transport',
      children: <>Transporte</>,
      isActive: whoIsActive === 'transport',
      action: (_, target) => setWhoIsActive(target.id),
    },
  ]

  if (proposal?.state === 'Awarded')
    buttonTabsItems.push({
      id: 'execution',
      children: <>Ejecución</>,
      isActive: whoIsActive === 'execution',
      action: (_, target) => setWhoIsActive(target.id),
    })

  return (
    <Flex
      flexDirection="column"
      alignItems="baseline"
      paddingX={0}
      margin="0 auto"
      width="100%"
      height="100%">
      <ButtonTabs items={buttonTabsItems} />
      <Box paddingY={5} width="100%">
        {whoIsActive === 'info' && <DetailProposalInfo proposal={proposal} />}
        {whoIsActive === 'conditions' && (
          <DetailProposalConditions bidPackage={bidPackage} proposal={proposal} />
        )}
        {whoIsActive === 'bidItems' && (
          <DetailProposalBidItems
            proposalItems={proposalItems}
            handleExportBidPackagePrices={handleExportBidPackagePrices}
          />
        )}
        {whoIsActive === 'emissionCost' && (
          <DetailProposalCostEmission proposal={proposal} proposalItems={proposalItems} />
        )}
        {whoIsActive === 'environmentalStatement' && (
          <DetailEnvironmentalStatement proposal={proposal} />
        )}
        {whoIsActive === 'execution' && (
          <DetailProposalExecutions
            proposalId={proposal?.proposalId}
            proposalItems={proposalItems}
          />
        )}
        {whoIsActive === 'transport' && (
          <DetailProposalTransports proposalId={proposal?.proposalId} />
        )}
      </Box>
    </Flex>
  )
}
