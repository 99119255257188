import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import Logout from '@proveoeng/uikit/dist/atoms/Icons/Logout'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { deleteLocalStorage } from '../../functions/cookies'

const ContainerStyled = styled(Flex)`
  position: fixed;
  width: 88px;
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 24px 0;
  @media (max-width: 768px) {
    height: auto;
    min-height: auto;
    top: 0;
    right: 5px;
    flex-direction: row;
    padding: 0;
  }
`

export const Sidebar1 = ({ placeholder, src }) => {
  const history = useHistory()

  return (
    <ContainerStyled>
      <Icon
        sizeIcon="display30"
        paddingX={3}
        paddingY={3}
        title="Cerrar sesión"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          deleteLocalStorage(
            process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env.REACT_APP_COOKIE_LOGIN_NAME,
          )
          history.push('/login')
        }}>
        <Logout />
      </Icon>
      <Link to="/perfil/proveedor" style={{ cursor: 'pointer' }}>
        <Avatar src={src} placeholder={placeholder} />
      </Link>
    </ContainerStyled>
  )
}

Sidebar1.propTypes = {
  placeholder: PropTypes.string,
  src: PropTypes.string,
}
Sidebar1.defaultProps = {
  placeholder: null,
  src: null,
}
