import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { FormatDate } from '../../../../../common/mappers/FormatDate'
import { parseToString } from '../../../../../../lib/utils/Format'
import { ModalDeleteDeclaration } from './ModalDeleteDeclaration'

export const ModalProjectFuelSpendList = ({
  isOpened = false,
  handleClose,
  fuelDeclarations = {},
  handleDeleteDeclaration,
  openEditModal,
}) => {
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState({
    show: false,
    declarationId: null,
  })

  const handleDeleteModalClose = () => setIsDeleteModalOpened({ show: false, declarationId: null })

  return (
    <>
      <Modal
        isOpen={isOpened}
        id="declaration-fuel-modal-list"
        onModalClose={handleClose}
        title="Consumos de gasoil"
        closeWithButton
        width="850px">
        <form onSubmit={() => {}} style={{ width: '100%' }}>
          <Modal.Content>
            <Grid
              gridTemplateAreas={"'declaration liters emittedKg accumulatedECo2 delete'"}
              gridTemplateColumns="1fr 130px 130px 130px 75px"
              bg="gray4"
              width="100%"
              paddingY={3}
              borderRadius={2}>
              <GridItem gridArea="declaration" paddingX={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Declaración
                </Text>
              </GridItem>
              <GridItem gridArea="liters" paddingX={4}>
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Litros
                </Text>
              </GridItem>
              <GridItem gridArea="emittedKg" paddingX={4}>
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Kg CO2 emitido
                </Text>
              </GridItem>
              <GridItem gridArea="accumulatedECo2" paddingX={4}>
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Kg CO2 emitido acumulado
                </Text>
              </GridItem>
              <GridItem gridArea="delete" paddingX={4} />
            </Grid>
            {fuelDeclarations?.map((declaration) => (
              <Grid
                gridTemplateAreas={"'declaration liters emittedKg accumulatedECo2 delete'"}
                gridTemplateColumns="1fr 130px 130px 130px 75px"
                paddingY={3}
                width="100%"
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <GridItem
                  gridArea="declaration"
                  display="flex"
                  style={{ alignItems: 'flex-start' }}>
                  <Text sizeText="display14" fontWeight="medium" color="gray1" paddingX={4}>
                    {FormatDate.hydrate(declaration.resource.declaredAt)?.readableReverseDate}
                  </Text>
                </GridItem>
                <GridItem
                  gridArea="liters"
                  display="flex"
                  style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                  <Button
                    colorType="none"
                    sizeButton="none"
                    action={() => openEditModal({ show: true, data: declaration.resource })}>
                    <Text sizeText="display12" color="gray1" paddingX={4}>
                      {parseToString(declaration.resource.declaredAmount, 3)}
                    </Text>
                  </Button>
                </GridItem>
                <GridItem
                  gridArea="emittedKg"
                  display="flex"
                  style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                  <Text sizeText="display12" color="gray1" paddingX={4}>
                    {parseToString(declaration.resource.declaredECo2, 2)}
                  </Text>
                </GridItem>
                <GridItem
                  gridArea="accumulatedECo2"
                  display="flex"
                  style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                  <Text sizeText="display12" color="gray1" paddingX={4}>
                    {parseToString(declaration.resource.accumulatedECo2, 2)}
                  </Text>
                </GridItem>
                <GridItem
                  gridArea="delete"
                  display="flex"
                  style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <FontAwesomeIcon
                    icon={faX}
                    size="sm"
                    color="#E67975"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setIsDeleteModalOpened({
                        show: true,
                        declarationId: declaration.resource.declarationId,
                      })
                    }
                  />
                </GridItem>
              </Grid>
            ))}
          </Modal.Content>
          <Modal.Actions>
            <Flex justifyContent="flex-end">
              <Grid gridTemplateRows="1fr" paddingRight={5}>
                <Button colorType="transparent" fullWidth action={handleClose}>
                  Hecho
                </Button>
              </Grid>
            </Flex>
          </Modal.Actions>
        </form>
      </Modal>
      <ModalDeleteDeclaration
        isDeleteModalOpened={isDeleteModalOpened.show}
        handleDeleteDeclaration={() => {
          handleDeleteDeclaration(isDeleteModalOpened.declarationId)
          handleDeleteModalClose()
        }}
        handleDeleteModalClose={handleDeleteModalClose}
        declarationToDelete={isDeleteModalOpened.declarationId}
      />
    </>
  )
}
