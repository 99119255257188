import { getParamsSerializer } from '../../../../lib/request'

export const services = {
  getCatalog: (params = {}) => ({
    route: `/enviro/catalog`,
    method: 'get',
    params,
    paramsSerializer: getParamsSerializer,
  }),
  createCatalog: (body) => ({
    route: `/enviro/catalog`,
    method: 'post',
    body,
  }),
  getDeclarations: (proposalId) => ({
    route: `/enviro/proposals/${proposalId}/declarations`,
    method: 'get',
  }),
  addDeclaration: (proposalId, declarationId, body) => ({
    route: `/enviro/proposals/${proposalId}/declarations/${declarationId}`,
    method: 'put',
    body,
  }),
  editDeclaration: (proposalId, declarationId, body) => ({
    route: `/enviro/proposals/${proposalId}/declarations/${declarationId}`,
    method: 'post',
    body,
  }),
  getProjectDeclarations: (projectId) => ({
    route: `/enviro/project/${projectId}/execution/declarations`,
    method: 'get',
  }),
  getEnviroProject: (projectId) => ({
    route: `/enviro/projects/${projectId}`,
    method: 'get',
  }),
  updateEnviroProject: (projectId, body) => ({
    route: `/enviro/projects/${projectId}`,
    method: 'post',
    body,
  }),
  deleteDeclaration: (proposalId, declarationId) => ({
    route: `/enviro/proposals/${proposalId}/declarations/${declarationId}`,
    method: 'delete',
  }),
  addSpendDeclaration: (projectId, declarationId, body) => ({
    route: `/enviro/project/${projectId}/resources/declarations/${declarationId}`,
    method: 'put',
    body,
  }),
  editSpendDeclaration: (projectId, declarationId, body) => ({
    route: `/enviro/project/${projectId}/resources/declarations/${declarationId}`,
    method: 'post',
    body,
  }),
  getEnergyDeclarations: (projectId) => ({
    route: `/enviro/project/${projectId}/resource/declarations?resource=Energy`,
    method: 'get',
  }),
  getFuelDeclarations: (projectId) => ({
    route: `/enviro/project/${projectId}/resource/declarations?resource=Fuel`,
    method: 'get',
  }),
  getWaterDeclarations: (projectId) => ({
    route: `/enviro/project/${projectId}/resource/declarations?resource=Water`,
    method: 'get',
  }),
  deleteSpendDeclaration: (projectId, declarationId) => ({
    route: `/enviro/project/${projectId}/resources/declarations/${declarationId}`,
    method: 'delete',
  }),
  getDemolitionWasteManagement: (projectId) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items?filterBy=stage[eq]=Demolition`,
    method: 'get',
  }),
  getBuildingWasteManagement: (projectId) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items?filterBy=stage[eq]=Building`,
    method: 'get',
  }),
  getWasteMaterials: () => ({
    route: `/enviro/waste-management/materials`,
    method: 'get',
  }),
  getPlannedTreatments: () => ({
    route: `/enviro/waste-management/treatments`,
    method: 'get',
  }),
  getWasteSellers: () => ({
    route: `/enviro/waste-management/sellers`,
    method: 'get',
  }),
  addDemolitionWasteManagement: (projectId, planItemId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items/${planItemId}`,
    method: 'put',
    body,
  }),
  editDemolitionWasteManagement: (projectId, planItemId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items/${planItemId}`,
    method: 'post',
    body,
  }),
  addBuildingWasteManagement: (projectId, planItemId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items/${planItemId}`,
    method: 'put',
    body,
  }),
  editBuildingWasteManagement: (projectId, planItemId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/plan-items/${planItemId}`,
    method: 'post',
    body,
  }),
  getWasteDeclarations: (projectId, planItemId) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations${
      planItemId ? `?filterBy=planItemId[eq]=${planItemId}` : ''
    }`,
    method: 'get',
  }),
  getWasteDeclaration: (projectId, declarationId) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations/${declarationId}`,
    method: 'get',
  }),
  addWasteDeclaration: (projectId, declarationId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations/${declarationId}`,
    method: 'put',
    body,
  }),
  editWasteDeclaration: (projectId, declarationId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations/${declarationId}`,
    method: 'post',
    body,
  }),
  setDeclarationFiles: (projectId, declarationId, fileId, body) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations/${declarationId}/files/${fileId}`,
    method: 'post',
    headers: {
      accept: '*/*',
    },
    formData: true,
    body,
  }),
  deleteDeclarationFiles: (projectId, declarationId, fileId) => ({
    route: `/enviro/projects/${projectId}/waste-management/declarations/${declarationId}/files/${fileId}`,
    method: 'delete',
  }),
  getTransportDeclarations: (proposalId) => ({
    route: `/enviro/proposals/${proposalId}/transport-declarations`,
    method: 'get',
  }),
  addTransportDeclaration: (proposalId, declarationId, body) => ({
    route: `/enviro/proposals/${proposalId}/transport-declarations/${declarationId}`,
    method: 'put',
    body,
  }),
  editTransportDeclaration: (proposalId, declarationId, body) => ({
    route: `/enviro/proposals/${proposalId}/transport-declarations/${declarationId}`,
    method: 'post',
    body,
  }),
  getTransportVehicles: () => ({
    route: `/enviro/transportation/vehicles`,
    method: 'get',
  }),
  getProjectTransportDeclarations: (projectId) => ({
    route: `/enviro/projects/${projectId}/transport/declarations`,
    method: 'get',
  }),
}
