import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { ModalProjectEnergySpend } from './ModalProjectEnergySpend'
import { ModalProjectEnergySpendList } from './ModalProjectEnergySpendList'
import { useProject } from '../../../../../common/hooks/useProject'
import { parseToInteger, parseToString } from '../../../../../../lib/utils/Format'
import { FormatDate } from '../../../../../common/mappers/FormatDate'

export const ProjectEnergySpend = ({ project }) => {
  const [isModalOpened, setIsModalOpened] = useState({ show: false, data: [] })
  const [isModalListOpened, setIsModalListOpened] = useState({ show: false, data: [] })
  const handleModalClose = () => setIsModalOpened({ show: false, data: [] })
  const handleModalListClose = () => setIsModalListOpened({ show: false, data: [] })

  const {
    data: { energyDeclarations },
    addSpendDeclaration,
    editSpendDeclaration,
    getEnergyDeclarations,
    deleteSpendDeclaration,
  } = useProject()

  useEffect(() => {
    const loadEnergyDeclarations = async () => {
      await getEnergyDeclarations(project.projectId)
    }
    if (project.projectId) {
      loadEnergyDeclarations()
    }
  }, [project.projectId])

  const handleAddEnergyDeclaration = async (values, declarationId = null) => {
    const parseDeclarationId = declarationId || uuidv4()
    const amount = parseToInteger(values.declaredAmount, 3)

    if (declarationId) {
      await editSpendDeclaration(project.projectId, declarationId, {
        amount,
        resource: 'Energy',
      })
    } else {
      await addSpendDeclaration(project.projectId, parseDeclarationId, {
        amount,
        resource: 'Energy',
      })
    }

    await getEnergyDeclarations(project.projectId)
  }

  const handleDeleteDeclaration = async (declarationId) => {
    await deleteSpendDeclaration(project.projectId, declarationId)
    await getEnergyDeclarations(project.projectId)
  }

  return (
    <>
      <Flex paddingBottom={4}>
        <Text sizeText="display16" fontWeight="medium" pr={2}>
          Consumo de energía eléctrica
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <PlusCircle sizeIcon="display18" />
          <Text
            color="gray1"
            sizeText="display14"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setIsModalOpened({ show: true, data: [] })}>
            Añadir declaración
          </Text>
        </Flex>
      </Flex>
      <Grid
        gridTemplateAreas={"'declaration declaredAmount accumulatedKg seeDeclaration'"}
        gridTemplateColumns="1fr 130px 130px 190px"
        bg="gray4"
        width="100%"
        paddingY={4}
        borderRadius={2}>
        <GridItem gridArea="declaration" paddingX={4}>
          <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
            Declaración
          </Text>
        </GridItem>
        <GridItem gridArea="declaredAmount" paddingX={4}>
          <Text
            sizeText="display12"
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            kwh acumulado
          </Text>
        </GridItem>
        <GridItem gridArea="accumulatedKg" paddingX={4}>
          <Text
            sizeText="display12"
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            Kg CO2 emitido acumulado
          </Text>
        </GridItem>
        <GridItem gridArea="seeDeclaration" />
      </Grid>
      {energyDeclarations?.data?.length === 0 && (
        <Flex
          paddingY={3}
          flexWrap="wrap"
          paddingLeft={4}
          borderBottomColor="gray4"
          borderBottomStyle="solid"
          borderBottomWidth="1px">
          <Text sizeText="display12" color="gray2" overflow="hidden">
            No hay ninguna declaración. Haz clic en &quot;Añadir declaración&quot; para añadir una
          </Text>
        </Flex>
      )}
      {energyDeclarations?.data?.length > 0 && (
        <Grid
          gridTemplateAreas={"'declaration declaredAmount accumulatedKg seeDeclaration'"}
          gridTemplateColumns="1fr 130px 130px 190px"
          paddingY={3}
          width="100%"
          borderBottomColor="gray4"
          borderBottomStyle="solid"
          borderBottomWidth="1px">
          <GridItem gridArea="declaration" paddingX={4}>
            <Text
              sizeText="display16"
              fontWeight="medium"
              color="gray1"
              onClick={() =>
                setIsModalOpened({ show: true, data: energyDeclarations?.data[0].resource })
              }
              style={{ cursor: 'pointer' }}>
              {
                FormatDate.hydrate(energyDeclarations?.data[0]?.resource?.declaredAt)
                  ?.readableReverseDate
              }
            </Text>
          </GridItem>
          <GridItem
            gridArea="declaredAmount"
            paddingX={4}
            style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
            <Text sizeText="display14" color="gray1" textAlign="right">
              {parseToString(energyDeclarations?.data[0]?.resource?.accumulatedAmount, 3)}
            </Text>
          </GridItem>
          <GridItem
            gridArea="accumulatedKg"
            paddingX={4}
            style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
            <Text sizeText="display14" color="gray1" textAlign="right">
              {parseToString(energyDeclarations?.data[0]?.resource?.accumulatedECo2, 3)}
            </Text>
          </GridItem>
          <GridItem gridArea="seeDeclaration">
            <Button
              colorType="none"
              sizeButton="none"
              action={() => setIsModalListOpened({ show: true, data: [] })}>
              <FontAwesomeIcon
                icon={faEye}
                size="lg"
                color="#868DA1"
                style={{ paddingRight: '4px' }}
              />
              <Text
                as="span"
                marginLeft={1}
                sizeText="display16"
                color="gray2"
                fontWeight="medium"
                style={{ cursor: 'pointer' }}>
                Ver declaraciones
              </Text>
            </Button>
          </GridItem>
        </Grid>
      )}

      <ModalProjectEnergySpend
        isOpened={isModalOpened.show}
        handleClose={handleModalClose}
        data={isModalOpened?.data}
        addEnergyDeclaration={handleAddEnergyDeclaration}
      />

      <ModalProjectEnergySpendList
        isOpened={isModalListOpened.show}
        handleClose={handleModalListClose}
        energyDeclarations={energyDeclarations?.data || []}
        handleDeleteDeclaration={handleDeleteDeclaration}
        openEditModal={setIsModalOpened}
      />
    </>
  )
}
