import { useRef } from 'react'

import { Avatar } from '@proveoeng/uikit/dist/atoms/Avatar'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { UploaderLink } from '@proveoeng/uikit/dist/atoms/Uploader'

export const SelectAvatar = ({
  description = 'Cambiar',
  avatar,
  handleChangeAvatar = () => {},
}) => {
  const imgRef = useRef()
  return (
    <>
      <Flex>
        <Flex alignItems="center" position="relative">
          <Avatar marginRight={4} src={avatar} ref={imgRef} width="64px" height="64px" />
          <img
            alt=""
            ref={imgRef}
            style={{
              display: 'none',
              marginRight: '16px',
              borderRadius: '99em',
              objectFit: 'cover',
              backgroundColor: '#efefef',
              border: '1px solid #efefef',
            }}
          />
          <UploaderLink
            imgRef={imgRef}
            onImagesLoad={handleChangeAvatar}
            description={description}
            name="avatar"
            accept="images"
            sizeText="display14"
          />
        </Flex>
      </Flex>
    </>
  )
}
