import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useEffect } from 'react'
import { useProject } from '../../../../common/hooks/useProject'
import { ProjectTransportDetails } from './ProjectTransportDetails'

export const ProjectTransport = ({ projectId }) => {
  const {
    data: { transportDeclarations },
    getProjectTransportDeclarations,
  } = useProject()

  useEffect(() => {
    const loadTransportDeclarations = async () => {
      await getProjectTransportDeclarations(projectId)
    }

    if (projectId) {
      loadTransportDeclarations()
    }
  }, [projectId])

  return (
    <>
      <Grid
        gridTemplateAreas={"'material date type km weight kgEmit'"}
        gridTemplateColumns="1fr 100px 145px 110px 110px 110px"
        bg="gray4"
        width="100%"
        paddingY={4}
        borderRadius={2}>
        <GridItem gridArea="material">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            Material
          </Text>
        </GridItem>
        <GridItem gridArea="date">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            Fecha de transporte
          </Text>
        </GridItem>
        <GridItem gridArea="type">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium">
            Tipo de vehículo
          </Text>
        </GridItem>
        <GridItem gridArea="km">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            km
          </Text>
        </GridItem>
        <GridItem gridArea="weight">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            Peso transp. (kg)
          </Text>
        </GridItem>
        <GridItem gridArea="kgEmit">
          <Text
            sizeText="display12"
            paddingX={4}
            color="gray1"
            overflow="hidden"
            fontWeight="medium"
            textAlign="right">
            kg CO2eq emitido
          </Text>
        </GridItem>
      </Grid>
      {transportDeclarations?.data?.map((declaration) => (
        <ProjectTransportDetails declaration={declaration} />
      ))}
    </>
  )
}
