import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { parseToInteger, parseToString } from '../../../../../../lib/utils/Format'
import { useProject } from '../../../../../common/hooks/useProject'

export const ModalProjectBuildingWasteManagementMaterial = ({
  isOpened = false,
  handleClose,
  data = [],
  projectId,
}) => {
  const initialValues = {
    material: data?.materialId
      ? [
          {
            label: `${data?.materialCode} - ${data?.materialName}`,
            value: data?.materialId,
          },
        ]
      : '',
    accumulation: [
      {
        label: data?.separateCollection ? 'Sí' : 'No',
        value: data?.separateCollection ? '1' : '0',
      },
    ],
    egr: data?.expectedAmount ? parseToString(data?.expectedAmount, 3) : '',
    plannedTreatment: data?.treatmentId
      ? [
          {
            label: data?.treatmentName,
            value: data?.treatmentId,
          },
        ]
      : '',
    manager: data?.wasteManagerCompanyId
      ? [
          {
            label: data?.wasteManagerCompanyName,
            value: data?.wasteManagerCompanyId,
          },
        ]
      : '',
  }
  const {
    data: { wasteMaterials, plannedTreatments, wasteSellers },
    getWasteMaterials,
    getPlannedTreatments,
    getWasteSellers,
    addBuildingWasteManagement,
    editBuildingWasteManagement,
    getBuildingWasteManagement,
  } = useProject()
  const [wasteMaterialOptions, setWasteMaterialOptions] = useState([])
  const accumulationOptions = [
    { label: 'Sí', value: '1' },
    { label: 'No', value: '0' },
  ]
  const [plannedTreatmentOptions, setPlannedTreatmentOptions] = useState([])
  const [wasteSellersOptions, setWasteSellersOptions] = useState([])
  const [firstRender, setFirstRender] = useState(true)
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(false)
  const handleAddBuildingWasteManagement = async () => {
    const body = {
      stage: 'Building',
      materialId: values?.material,
      treatmentId: values?.plannedTreatment,
      expectedAmount: parseToInteger(values?.egr, 3),
      separateCollection: values?.accumulation === '1',
      wasteManagerCompanyId: values?.manager,
    }
    const planItemId = uuidv4()
    await addBuildingWasteManagement(projectId, planItemId, body)
    await getBuildingWasteManagement(projectId)
    handleClose()
  }

  const handleEditBuildingWasteManagement = async () => {
    const body = {
      stage: 'Building',
      materialId: Array.isArray(values?.material) ? values?.material[0]?.value : values?.material,
      treatmentId: Array.isArray(values?.plannedTreatment)
        ? values?.plannedTreatment[0]?.value
        : values?.plannedTreatment,
      expectedAmount: parseToInteger(values?.egr, 3),
      separateCollection: Array.isArray(values?.accumulation)
        ? values?.accumulation[0]?.value === '1'
        : values?.accumulation === '1',
      wasteManagerCompanyId: Array.isArray(values?.manager)
        ? values?.manager[0]?.value
        : values?.manager,
    }
    await editBuildingWasteManagement(projectId, data?.planItemId, body)
    await getBuildingWasteManagement(projectId)
    handleClose()
  }

  const { values, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit: data?.createdAt
      ? handleEditBuildingWasteManagement
      : handleAddBuildingWasteManagement,
    enableReinitialize: true,
  })

  useEffect(() => {
    const loadWasteMaterials = async () => {
      await getWasteMaterials()
    }
    const loadWasteSellers = async () => {
      await getWasteSellers()
    }

    loadWasteMaterials()
    loadWasteSellers()
  }, [])

  useEffect(() => {
    if (values?.material) {
      const loadPlannedTreatments = async () => {
        setIsTreatmentLoading(true)
        setPlannedTreatmentOptions([])
        await getPlannedTreatments()
        setIsTreatmentLoading(false)
      }
      loadPlannedTreatments()
    }
    if (firstRender && data) {
      setFirstRender(false)
    } else {
      setPlannedTreatmentOptions([])
      setFieldValue('plannedTreatment', '')
    }
  }, [values?.material])

  useEffect(() => {
    setWasteMaterialOptions(
      wasteMaterials?.data?.map((option) => ({
        label: `${option?.resource?.code} - ${option?.resource?.name}`,
        value: option?.resource?.materialId,
      })),
    )
  }, [wasteMaterials])

  useEffect(() => {
    setWasteSellersOptions(
      wasteSellers?.data?.map((option) => ({
        label: option?.resource?.name,
        value: option?.resource?.companyId,
      })),
    )
  }, [wasteSellers])

  useEffect(() => {
    const material = wasteMaterials?.data?.find(
      (option) =>
        option?.resource?.materialId === data?.materialId ||
        option?.resource?.materialId === values?.material,
    )

    setPlannedTreatmentOptions(
      plannedTreatments?.data
        ?.filter((option) => option?.resource?.wasteLevel === material?.resource?.wasteLevel)
        .map((option) => ({
          label: option?.resource?.name,
          value: option?.resource?.treatmentId,
        })),
    )
  }, [plannedTreatments?.data])

  useEffect(() => {
    resetForm()
  }, [data])

  return (
    <Modal
      isOpen={isOpened}
      id="declaration-Building-modal"
      onModalClose={handleClose}
      title={data?.createdAt ? 'Editar planificación' : 'Añadir material'}
      closeWithButton
      width="480px">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Modal.Content>
          <Field label="Material" required sizeText="display14" marginBottom={3}>
            <SelectInput
              isSearchable
              options={wasteMaterialOptions}
              id="material"
              onChange={(_, v) => setFieldValue('material', v.value)}
              defaultValue={values?.material}
            />
          </Field>
          <Field label="Acopio separado" sizeText="display14" marginBottom={3}>
            <SelectInput
              isSearchable
              options={accumulationOptions}
              id="accumulation"
              onChange={(_, v) => setFieldValue('accumulation', v.value)}
              defaultValue={values?.accumulation}
            />
          </Field>
          <Field label="Previsto EGR (kg)" sizeText="display14" marginBottom={3}>
            <InputNumber
              name="egr"
              placeholderMessage="0,000"
              ariaLabel="egr"
              value={values?.egr}
              onChange={handleChange}
              numeralDecimalScale={3}
            />
          </Field>
          <Field label="Tratamiento previsto" required sizeText="display14" marginBottom={3}>
            {!isTreatmentLoading && (
              <SelectInput
                required
                isSearchable
                options={plannedTreatmentOptions}
                id="plannedTreatment"
                onChange={(_, v) => setFieldValue('plannedTreatment', v.value)}
                defaultValue={values?.plannedTreatment}
              />
            )}
            {isTreatmentLoading && (
              <SelectInput
                required
                isSearchable
                options={[]}
                id="plannedTreatment2"
                onChange={() => undefined}
                defaultValue={[]}
              />
            )}
          </Field>
          <Field label="Gestor" required sizeText="display14" marginBottom={3}>
            <SelectInput
              required
              isSearchable
              options={wasteSellersOptions}
              id="manager"
              onChange={(_, v) => setFieldValue('manager', v.value)}
              defaultValue={values?.manager}
            />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateRows="1fr" paddingRight={5}>
              <Button colorType="transparent" fullWidth action={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid gridTemplateRows="1fr">
              <Button
                colorType="orange"
                fullWidth
                type="submit"
                disabled={!values?.material || !values?.plannedTreatment || !values?.manager}>
                {data?.createdAt ? 'Guardar' : 'Confirmar'}
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </form>
    </Modal>
  )
}
