import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'

import { faEdit, faExclamationTriangle, faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { PlusCircle } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { parseToString } from '../../../../../lib/utils/Format'
import { useProject } from '../../../../common/hooks/useProject'
import { ModalProjectBuildingWasteManagementMaterial } from './Building/ModalProjectBuildingWasteManagementMaterial'
import { ModalProjectDemolitionWasteManagementMaterial } from './Demolition/ModalProjectDemolitionWasteManagementMaterial'
import { ModalProjectWasteManagementDeclaration } from './ModalProjectWasteManagementDeclaration'
import { FormatDate } from '../../../../common/mappers/FormatDate'

export const ProjectWasteManagementDeclarations = () => {
  const history = useHistory()
  const [isBuildingModalOpened, setIsBuildingModalOpened] = useState(false)
  const [isDemolitionModalOpened, setIsDemolitionModalOpened] = useState(false)
  const { projectId, planItemId, type } = useParams()
  const {
    data: { project, demolitionWasteManagement, buildingWasteManagement, wasteDeclarations },
    getProject,
    getDemolitionWasteManagement,
    getBuildingWasteManagement,
    getWasteDeclarations,
    getWasteDeclaration,
  } = useProject()
  const getTitlePlanification = () => {
    if (type === 'demolicion') return 'Planificación - Demolición'
    if (type === 'obra') return 'Planificación - Obra'

    return ''
  }
  const openModal = (type) => {
    if (type === 'demolicion') setIsDemolitionModalOpened(true)
    if (type === 'obra') setIsBuildingModalOpened(true)
  }
  const [isDeclarationModalOpened, setIsDeclarationModalOpened] = useState(false)
  const closeModal = () => {
    setIsDemolitionModalOpened(false)
    setIsBuildingModalOpened(false)
    setIsDeclarationModalOpened(false)
    setDeclaration([])
  }
  const [declaration, setDeclaration] = useState([])
  const goBack = () => history.push(`/constructora/tus-obras/${projectId}`)
  const [planification, setPlanification] = useState(null)

  const handleEditDeclaration = async (declarationId) => {
    const decl = await getWasteDeclaration(projectId, declarationId)
    setDeclaration(decl?.data?.data)
    setIsDeclarationModalOpened(true)
  }

  const getTotalDeclarationsEGR = () =>
    wasteDeclarations?.data?.reduce((acc, declaration) => acc + declaration?.resource?.amount, 0)

  const getEGRPercentage = (egrAmount = 0) => {
    const totalDeclarations = getTotalDeclarationsEGR()

    return `${parseToString((totalDeclarations / egrAmount) * 10000, 2)}%`
  }

  const getExclamationIcon = (egrAmount = 0) => {
    const totalDeclarations = getTotalDeclarationsEGR()

    if (egrAmount === 0 || totalDeclarations === 0) return null

    if (totalDeclarations / egrAmount > 0.8) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="lg"
          color="#D9993B"
          style={{ paddingRight: '4px' }}
        />
      )
    }

    return null
  }

  useEffect(() => {
    const loadProjectData = async () => {
      await getProject(projectId)
    }
    const loadWasteManagement = async () => {
      if (type === 'demolicion') await getDemolitionWasteManagement(projectId)
      if (type === 'obra') await getBuildingWasteManagement(projectId)
    }

    if (
      projectId &&
      !isBuildingModalOpened &&
      !isDemolitionModalOpened &&
      !isDeclarationModalOpened
    ) {
      loadProjectData()
      loadWasteManagement()
    }
  }, [projectId, isBuildingModalOpened, isDemolitionModalOpened, isDeclarationModalOpened])

  useEffect(() => {
    const loadWasteDeclarations = async () => {
      await getWasteDeclarations(projectId, planification?.planItemId)
    }

    if (planification?.planItemId) {
      loadWasteDeclarations()
    }
  }, [planification?.planItemId])

  useEffect(() => {
    if (demolitionWasteManagement && planItemId && type === 'demolicion') {
      const demolitionPlan = demolitionWasteManagement?.data?.filter(
        (planification) => planItemId === planification?.resource.planItemId,
      )
      if (demolitionPlan) setPlanification(demolitionPlan[0].resource)
    }
    if (buildingWasteManagement && planItemId && type === 'obra') {
      const buildingPlan = buildingWasteManagement?.data?.filter(
        (planification) => planItemId === planification?.resource.planItemId,
      )
      if (buildingPlan) setPlanification(buildingPlan[0].resource)
    }
  }, [demolitionWasteManagement, buildingWasteManagement, planItemId])

  return (
    <>
      <Flex>
        <Flex height="100%" width="100%" paddingY={4} flexDirection="column" margin="0 auto">
          <Text as="span" sizeText="display14" color="gray2" paddingBottom={6}>
            <Text
              as="span"
              sizeText="display14"
              color="blue1"
              marginBottom={7}
              style={{ cursor: 'pointer' }}
              onClick={goBack}>
              {'<'} Volver
            </Text>{' '}
            |{' '}
            <Text
              as="span"
              sizeText="display14"
              marginX={1}
              color="gray2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => history.push('/constructora/tus-obras')}>
              Tus obras
            </Text>{' '}
            /
            <Text
              as="span"
              sizeText="display14"
              marginX={1}
              color="gray2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => history.push(`/constructora/tus-obras/${projectId}`)}>
              {project?.name}
            </Text>{' '}
            /{' '}
            <Text
              as="span"
              sizeText="display14"
              marginX={1}
              color="gray2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => history.push(`/constructora/tus-obras/${projectId}`)}>
              Residuos
            </Text>{' '}
            /
            <Text as="span" sizeText="display14" color="black">
              {' '}
              Declaraciones
            </Text>
          </Text>
          <Flex width="100%" justifyContent="space-between" marginBottom={6}>
            <Flex alignItems="center">
              <Text as="span" sizeText="display32" color="black" fontWeight="bold">
                Declaraciones durante la obra
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column" paddingBottom={6}>
            <Flex paddingBottom={4}>
              <Text sizeText="display16" fontWeight="medium" pr={4}>
                {getTitlePlanification()}
              </Text>
            </Flex>
            <Grid
              gridTemplateAreas={
                "'code material rcd type category accumulation egr plannedTreatment manager seeDeclarations'"
              }
              gridTemplateColumns="100px 1fr 90px 100px 80px 70px 90px 130px 130px 180px"
              bg="gray4"
              width="100%"
              paddingY={4}
              borderRadius={2}>
              <GridItem gridArea="code" paddingX={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Código residuo
                </Text>
              </GridItem>
              <GridItem gridArea="material" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Material
                </Text>
              </GridItem>
              <GridItem gridArea="rcd" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Tipo RCD
                </Text>
              </GridItem>
              <GridItem gridArea="type" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Tipo material
                </Text>
              </GridItem>
              <GridItem gridArea="category" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Naturaleza
                </Text>
              </GridItem>
              <GridItem gridArea="accumulation" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Acopio separado
                </Text>
              </GridItem>
              <GridItem gridArea="egr" paddingRight={4}>
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Previsto EGR (tn)
                </Text>
              </GridItem>
              <GridItem gridArea="plannedTreatment" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Tratamiento previsto
                </Text>
              </GridItem>
              <GridItem gridArea="manager" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Gestor
                </Text>
              </GridItem>
              <GridItem gridArea="seeDeclarations" />
            </Grid>
            <Grid
              gridTemplateAreas={
                "'code material rcd type category accumulation egr plannedTreatment manager seeDeclarations'"
              }
              gridTemplateColumns="100px 1fr 90px 100px 80px 70px 90px 130px 130px 180px"
              paddingY={3}
              width="100%"
              borderBottomColor="gray4"
              borderBottomStyle="solid"
              borderBottomWidth="1px">
              <GridItem gridArea="code" paddingX={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.materialCode}
                </Text>
              </GridItem>
              <GridItem gridArea="material" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.materialName}
                </Text>
              </GridItem>
              <GridItem gridArea="rcd" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.materialLevel}
                </Text>
              </GridItem>
              <GridItem gridArea="type" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.materialType}
                </Text>
              </GridItem>
              <GridItem gridArea="category" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.materialKind}
                </Text>
              </GridItem>
              <GridItem gridArea="accumulation" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.separateCollection ? 'Sí' : 'No'}
                </Text>
              </GridItem>
              <GridItem gridArea="egr" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden" textAlign="right">
                  <Flex>
                    {getExclamationIcon(planification?.expectedAmount)}{' '}
                    {parseToString(planification?.expectedAmount / 1000, 3)}
                  </Flex>
                </Text>
              </GridItem>
              <GridItem gridArea="plannedTreatment" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.treatmentName}
                </Text>
              </GridItem>
              <GridItem gridArea="manager" paddingRight={4}>
                <Text sizeText="display14" color="gray1" overflow="hidden">
                  {planification?.wasteManagerCompanyName}
                </Text>
              </GridItem>
              <GridItem gridArea="seeDeclarations">
                <Button colorType="none" sizeButton="none" action={() => openModal(type)}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="lg"
                    color="#868DA1"
                    style={{ paddingRight: '4px' }}
                  />
                  <Text
                    as="span"
                    marginLeft={1}
                    sizeText="display14"
                    color="gray2"
                    fontWeight="medium"
                    style={{ cursor: 'pointer' }}>
                    Editar planificación
                  </Text>
                </Button>
              </GridItem>
            </Grid>
          </Flex>
          <Flex width="100%" justifyContent="space-between" marginBottom={6}>
            <Text as="span" sizeText="display14" fontWeight="medium">
              EGR Actual ({getEGRPercentage(planification?.expectedAmount)}):{' '}
              <Text as="span" sizeText="display14">
                {parseToString(getTotalDeclarationsEGR() / 1000, 3)} tn
              </Text>
            </Text>
          </Flex>
          <Flex flexDirection="column" paddingBottom={6}>
            <Flex paddingBottom={4}>
              <Text sizeText="display16" fontWeight="medium" pr={4}>
                Declaraciones
              </Text>
              <Flex alignItems="center" justifyContent="center">
                <PlusCircle sizeIcon="display18" />
                <Text
                  color="gray1"
                  sizeText="display14"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsDeclarationModalOpened(true)}>
                  Añadir declaración
                </Text>
              </Flex>
            </Flex>
            <Grid
              gridTemplateAreas={
                "'declaration generate emissionDate treatment certificate seeDeclarations'"
              }
              gridTemplateColumns="115px 110px 110px 110px 1fr 170px"
              bg="gray4"
              width="100%"
              paddingY={4}
              borderRadius={2}>
              <GridItem gridArea="declaration" paddingX={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Declaración
                </Text>
              </GridItem>
              <GridItem gridArea="generate" paddingRight={4}>
                <Text
                  sizeText="display12"
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Generado (tn)
                </Text>
              </GridItem>
              <GridItem gridArea="emissionDate" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Fecha salida
                </Text>
              </GridItem>
              <GridItem gridArea="treatment" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Tratamiento final
                </Text>
              </GridItem>
              <GridItem gridArea="certificate" paddingRight={4}>
                <Text sizeText="display12" color="gray1" overflow="hidden" fontWeight="medium">
                  Certificado
                </Text>
              </GridItem>
              <GridItem gridArea="seeDeclarations" />
            </Grid>
            {wasteDeclarations?.data?.length === 0 && (
              <Flex
                paddingY={3}
                flexWrap="wrap"
                paddingLeft={4}
                borderBottomColor="gray4"
                borderBottomStyle="solid"
                borderBottomWidth="1px">
                <Text sizeText="display12" color="gray2" overflow="hidden">
                  No hay ninguna declaración. Haz clic en &quot;Añadir declaración&quot; para añadir
                  una
                </Text>
              </Flex>
            )}
            {wasteDeclarations?.data?.length > 0 &&
              wasteDeclarations?.data?.map((declaration) => (
                <Grid
                  gridTemplateAreas={
                    "'declaration generate emissionDate treatment certificate seeDeclarations'"
                  }
                  gridTemplateColumns="115px 110px 110px 110px 1fr 170px"
                  paddingY={3}
                  width="100%"
                  borderBottomColor="gray4"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px">
                  <GridItem gridArea="declaration" paddingX={4}>
                    <Text sizeText="display14" color="gray1" overflow="hidden">
                      {FormatDate.hydrate(declaration?.resource?.createdAt)?.readableReverseDate}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="generate" paddingRight={4}>
                    <Text sizeText="display14" color="gray1" overflow="hidden" textAlign="right">
                      {parseToString(declaration?.resource?.amount / 1000, 3)}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="emissionDate" paddingRight={4}>
                    <Text sizeText="display14" color="gray1" overflow="hidden">
                      {FormatDate.hydrate(declaration?.resource?.departedAt)?.readableReverseDate}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="treatment" paddingRight={4}>
                    <Text sizeText="display14" color="gray1" overflow="hidden">
                      {declaration?.resource?.treatmentName}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="certificate" paddingRight={4}>
                    <Text sizeText="display14" color="gray1" overflow="hidden">
                      {!declaration?.resource?.files?.length
                        ? '-'
                        : declaration?.resource?.files?.map((file) => (
                            <Flex paddingBottom={1}>
                              <FontAwesomeIcon
                                icon={faFile}
                                size="lg"
                                color="#868DA1"
                                style={{ paddingRight: '4px' }}
                              />
                              <Text sizeText="display14" color="gray1" overflow="hidden">
                                {file.name}
                              </Text>
                            </Flex>
                          ))}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="seeDeclarations">
                    <Button
                      colorType="none"
                      sizeButton="none"
                      action={() => handleEditDeclaration(declaration?.resource?.declarationId)}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        color="#868DA1"
                        style={{ paddingRight: '4px' }}
                      />
                      <Text
                        as="span"
                        marginLeft={1}
                        sizeText="display14"
                        color="gray2"
                        fontWeight="medium"
                        style={{ cursor: 'pointer' }}>
                        Editar declaración
                      </Text>
                    </Button>
                  </GridItem>
                </Grid>
              ))}
          </Flex>
        </Flex>
      </Flex>
      <ModalProjectBuildingWasteManagementMaterial
        isOpened={isBuildingModalOpened}
        handleClose={closeModal}
        data={planification}
        projectId={projectId}
      />
      <ModalProjectDemolitionWasteManagementMaterial
        isOpened={isDemolitionModalOpened}
        handleClose={closeModal}
        data={planification}
        projectId={projectId}
      />
      <ModalProjectWasteManagementDeclaration
        isOpened={isDeclarationModalOpened}
        handleClose={closeModal}
        data={declaration}
        planificationData={planification}
        projectId={projectId}
      />
    </>
  )
}
