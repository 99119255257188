import { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CreateProjectTemplate } from '../template/CreateProjectTemplate'

import { useProject } from '../../../../common/hooks/useProject'
import { CreateProjectNameTemplate } from '../template/CreateProjectNameTemplate'
import { CreateProjectDirectionTemplate } from '../template/CreateProjectDirectionTemplate'
import { CreateProjectDatesTemplate } from '../template/CreateProjectDatesTemplate'
import { CreateProjectDataTemplate } from '../template/CreateProjectDataTemplate'
import { CreateProjectCostTemplate } from '../template/CreateProjectCostTemplate'
import { CreateProjectAttachmentsTemplate } from '../template/CreateProjectAttachmentsTemplate'
import { CreateProjectSpendTemplate } from '../template/CreateProjectSpendTemplate'
import { ContainerProject } from '../components/ContainerCreateProject'

import { useCompany } from '../../../../common/hooks/useCompany'
import { useAttachmentLinks } from '../../../../common/hooks/useAttachmentLinks'
import { onImagesLoad } from '../../../../common/functions/data'
import { errorsImages } from '../../config'
import { LayoutCreate } from '../../../../common/components/Layout/LayoutCreate'

const pages = ['nombre', 'direccion', 'fechas', 'datos', 'costes', 'adjuntos', 'consumo']

// Get dinamy template depending on the url
const Templates = {
  nombre: (props) => <CreateProjectNameTemplate {...props} />,
  direccion: (props) => <CreateProjectDirectionTemplate {...props} />,
  fechas: (props) => <CreateProjectDatesTemplate {...props} />,
  datos: (props) => <CreateProjectDataTemplate {...props} />,
  costes: (props) => <CreateProjectCostTemplate {...props} />,
  adjuntos: (props) => <CreateProjectAttachmentsTemplate {...props} />,
  consumo: (props) => <CreateProjectSpendTemplate {...props} />,
}

const initialValues = {
  name: '',
  reference: '',
  address: '',
  city: '',
  postCode: '',
  start: '',
  end: '',
  customer: '',
  description: '',
  budget: '',
  expectedCosts: '',
  kZero: '',
  constructionType: '',
  projectType: '',
  energyECo2: '',
  fuelECo2: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre es requerido')
    .min(5, 'El nombre debe tener al menos 5 caracteres')
    .max(50, 'El nombre debe tener como máximo 50 caracteres'),
  reference: Yup.string(),
  address: Yup.string()
    .required('La dirección es requerida')
    .min(5, 'La dirección debe tener al menos 5 caracteres')
    .max(80, 'La dirección debe tener como máximo 80 caracteres'),
  city: Yup.string()
    .required('La ciudad es requerida')
    .min(2, 'La ciudad debe tener al menos 2 caracteres')
    .max(50, 'La ciudad debe tener como máximo 50 caracteres'),
  postCode: Yup.string()
    .required('El código postal es requerido')
    .matches(/^[0-9]{5}$/, 'El código postal es inválido'),
  start: Yup.date().required('La fecha de inicio prevista es requerida'),
  end: Yup.date()
    .required('La fecha de finalización prevista es requerida')
    .min(
      Yup.ref('start'),
      'La fecha de finalización prevista no puede ser anterior a la fecha de inicio prevista.',
    ),
  customer: Yup.string(),
  description: Yup.string(),
  budget: Yup.string(),
  expectedCosts: Yup.string(),
  kZero: Yup.string(),
  constructionType: Yup.string().required('El tipo de cliente es requerido'),
  projectType: Yup.string().required('El tipo de proyecto es requerido'),
  energyECo2: Yup.string(),
  fuelECo2: Yup.string(),
})

const CreateProjectPage = () => {
  const history = useHistory()
  const { hash, name } = useParams()

  const { employeeId } = useSelector((state) => state.employee?.data)
  const auth = useSelector((state) => state.auth)
  const { companyName } = useSelector((state) => state.company?.data)

  const [isOpenedPopupDuplicate, setIsOpenedPopupDuplicate] = useState(false)

  const {
    data: { projects, project },
    getProject,
    getAllProjects,
    actionProject,
    cancelRequest,
    createProject,
    updateProject,
    setLinksProject,
    deleteLinksProject,
    setFilesProject,
    deleteFilesProject,
  } = useProject()

  const { getCompany } = useCompany()

  const {
    links,
    inputLink,
    setInputLink,
    addLink,
    handleAddDescription,
    removeLink,
    deleteLinksId,
    verifyUrl,
    setLinks,
    setDeleteLinksId,
  } = useAttachmentLinks([])

  const [files, setFiles] = useState()
  const [deleteFiles, setDeleteFiles] = useState([])

  const onSubmitAttachment = async (projectId) => {
    await setFilesProject(projectId, files)
    await setLinksProject(projectId, links)
    await deleteFilesProject(projectId, deleteFiles)
    await deleteLinksProject(projectId, deleteLinksId)
  }

  const onSubmit = async (values) => {
    try {
      let projectId = ''
      if (hash) {
        await updateProject(hash, { ...values, employeeId })
        projectId = hash
      } else {
        projectId = await createProject({ ...values, employeeId })
      }
      await onSubmitAttachment(projectId)
      history.push(`/constructora/tus-obras/${projectId}`)
      return true
    } catch (error) {
      return false
    }
  }

  const {
    setValues,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const errorTouched = {
    name: !!touched.name && !!errors.name,
    reference: !!touched.reference && !!errors.reference,
    address: !!touched.address && !!errors.address,
    city: !!touched.city && !!errors.city,
    postCode: !!touched.postCode && !!errors.postCode,
    start: !!touched.start && !!errors.start,
    end: !!touched.end && !!errors.end,
    customer: !!touched.customer && !!errors.customer,
    description: !!touched.description && !!errors.description,
    budget: !!touched.budget && !!errors.budget,
    expectedCosts: !!touched.expectedCosts && !!errors.expectedCosts,
    kZero: !!touched.kZero && !!errors.kZero,
    constructionType: !!touched.constructionType && !!errors.constructionType,
    projectType: !!touched.projectType && !!errors.projectType,
    energyECo2: !!touched.energyECo2 && !!errors.energyECo2,
    fuelECo2: !!touched.fuelECo2 && !!errors.fuelECo2,
  }

  useEffect(() => {
    if (project?.projectId) {
      setValues({
        name: project.name || '',
        reference: project.reference || '',
        address: project.address || '',
        city: project.city || '',
        postCode: project.postCode || '',
        start: project.start || '',
        end: project.end || '',
        customer: project.customer || '',
        description: project.description || '',
        budget: project.budget || '',
        expectedCosts: project.expectedCosts || '',
        kZero: project.kZero || '',
        constructionType: project.constructionType || '',
        projectType: project.projectType || '',
        energyECo2: project.energyECo2 || '',
        fuelECo2: project.fuelECo2 || '',
      })
    }
  }, [project])

  useEffect(() => {
    setLinks(project?.links || [])
    setDeleteLinksId([])
  }, [project?.links])

  useEffect(() => {
    setFiles(project?.files)
  }, [project?.files])

  useEffect(
    // TODO: agregar alerta de que perderas los datos si sales de la pagina
    () => () => {
      cancelRequest()
    },
    [],
  )

  useEffect(() => {
    if (name && !Templates[name]) history.push('/constructora/crear-obra')
  }, [name])

  useEffect(() => {
    const loadProject = async () => {
      await getProject(hash)
    }
    if (hash) loadProject()
  }, [hash])

  useEffect(() => {
    if (auth?.data?.companyId) {
      getCompany(auth?.data?.companyId)
    }
  }, [auth])

  const handleOpenPopupDuplicate = async () => {
    await getAllProjects({}, false)
    setIsOpenedPopupDuplicate(true)
  }

  const handleClosePopupDuplicate = () => setIsOpenedPopupDuplicate(false)

  const onSubmitDuplication = async (projectId) => {
    await actionProject(projectId, 'Copy')
  }

  const goToCreateNew = () => history.push('/constructora/crear-obra/nombre')

  const goToHome = () => history.push(`/constructora/tus-obras${hash ? `/${hash}` : ''}`)

  const goToNextPage = () =>
    history.push(
      `/constructora/crear-obra/${pages[pages.indexOf(name) + 1]}${hash ? `/${hash}` : ''}`,
    )

  const goToPreviousPage = () =>
    history.push(
      `/constructora/crear-obra/${pages[pages.indexOf(name) - 1]}${hash ? `/${hash}` : ''}`,
    )

  const RenderTemplate = useCallback(
    (props) => {
      const Template = Templates[name] || Templates.nombre
      return <Template {...props} />
    },
    [name],
  )

  const onDeleteFile = (file) => {
    if (file?.fileId) {
      const updateFiles = files.filter((element) => element.fileId !== file.fileId)
      setFiles(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    } else {
      const updateFiles = files.filter((element) => element.name !== file.name)
      setFiles(updateFiles)
      setDeleteFiles((prev) => [...prev, file])
    }
  }

  if (!name)
    return (
      <LayoutCreate onSubmit={() => {}} actionLayout={() => {}} disableOptions>
        <CreateProjectTemplate
          onSubmitDuplication={onSubmitDuplication}
          projects={projects?.data}
          isOpenedPopupDuplicate={isOpenedPopupDuplicate}
          goToCreateNew={goToCreateNew}
          goToHome={goToHome}
          handleOpenPopupDuplicate={handleOpenPopupDuplicate}
          handleClosePopupDuplicate={handleClosePopupDuplicate}
        />
      </LayoutCreate>
    )

  return (
    <>
      <form onSubmit={handleSubmit}>
        <LayoutCreate
          onSubmit={() => {}}
          project={{ ...values, files, links }}
          errors={errorTouched}
          actionLayout={() => (
            <Flex justifyContent={hash ? 'space-between' : 'flex-end'} width="100%">
              {hash && (
                <Flex width={168}>
                  <Button
                    fullWidth
                    colorType="transparent"
                    action={() => history.push(`/constructora/tus-obras/${hash}`)}>
                    Ver obra
                  </Button>
                </Flex>
              )}
              <Flex>
                <Flex width={168} marginLeft={5}>
                  <Button
                    type="button"
                    fullWidth
                    action={goToPreviousPage}
                    sizeButton="medium"
                    colorType="transparent"
                    disabled={pages.indexOf(name) < 1}>
                    Anterior
                  </Button>
                </Flex>
                <Flex width={168} marginLeft={5}>
                  <Button
                    type="button"
                    fullWidth
                    action={goToNextPage}
                    sizeButton="medium"
                    colorType="transparent"
                    disabled={pages.indexOf(name) === pages.length - 1}>
                    Siguiente
                  </Button>
                </Flex>
                <Flex width={168} marginLeft={5}>
                  <Button fullWidth colorType="orange" sizeButton="medium" type="submit">
                    {hash ? 'Guardar' : 'Crear obra'}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}>
          <ContainerProject isEdit={!!hash} goToHome={goToHome}>
            {name !== 'adjuntos' ? (
              <RenderTemplate
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                companyName={companyName}
              />
            ) : (
              <CreateProjectAttachmentsTemplate
                onImagesLoad={(imgs) => onImagesLoad(imgs, setFiles, files)}
                history={history}
                onDeleteFile={onDeleteFile}
                errorsImages={errorsImages}
                data={files}
                links={links}
                addLink={addLink}
                removeLink={removeLink}
                inputLink={inputLink}
                setInputLink={setInputLink}
                handleAddDescription={handleAddDescription}
                verifyUrl={verifyUrl}
              />
            )}
          </ContainerProject>
        </LayoutCreate>
      </form>
    </>
  )
}

export default CreateProjectPage
