import { useEffect } from 'react'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'

import { useEnviro } from '../../../../common/hooks/useEnviro'
import { ProposalDeclarationsDetails } from './ProposalDeclarationsDetails'

export const ModalDetailProjectExecution = ({ isOpened = false, handleClose, element = {} }) => {
  const title = `${element.bidPackageName} (${element.sellerName})`

  const {
    data: { declarations },
    getDeclarations,
  } = useEnviro()

  useEffect(() => {
    if (element?.proposalId) {
      getDeclarations(element?.proposalId)
    }
  }, [element])

  return (
    <Modal
      isOpen={isOpened}
      id="declaration-modal"
      onModalClose={handleClose}
      title={title}
      closeWithButton
      minWidth="1250px">
      <Modal.Content>
        <Grid
          gridTemplateAreas={
            "'proposalItem unit offered executed accumulated kg total percentageRecycled kgRecycled kgRecycledTotal'"
          }
          gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
          bg="gray4"
          width="100%"
          paddingY={4}
          borderRadius={2}>
          <GridItem gridArea="proposalItem">
            <Text
              sizeText="display12"
              paddingX={4}
              paddingLeft={8}
              color="gray1"
              overflow="hidden"
              fontWeight="medium">
              Partida
            </Text>
          </GridItem>
          <GridItem gridArea="unit">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium">
              Unidades
            </Text>
          </GridItem>
          <GridItem gridArea="offered">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              Cantidad ofertada
            </Text>
          </GridItem>
          <GridItem gridArea="executed">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              Cantidad ejecutada
            </Text>
          </GridItem>
          <GridItem gridArea="accumulated">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              Cantidad acumulada
            </Text>
          </GridItem>
          <GridItem gridArea="kg">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              kg CO2 eq/Ud
            </Text>
          </GridItem>
          <GridItem gridArea="total">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              Total kg CO2 eq
            </Text>
          </GridItem>
          <GridItem gridArea="percentageRecycled">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              % mat. reciclados
            </Text>
          </GridItem>
          <GridItem gridArea="kgRecycled">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              kg reciclados
            </Text>
          </GridItem>
          <GridItem gridArea="kgRecycledTotal">
            <Text
              sizeText="display12"
              paddingX={4}
              color="gray1"
              overflow="hidden"
              fontWeight="medium"
              textAlign="right">
              kg rec. total
            </Text>
          </GridItem>
        </Grid>
        {declarations.map((declaration) => (
          <ProposalDeclarationsDetails declaration={declaration} />
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Flex justifyContent="flex-end">
          <Grid gridTemplateRows="1fr">
            <Button colorType="transparent" fullWidth action={handleClose}>
              Hecho
            </Button>
          </Grid>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}
