import { useState } from 'react'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal/Modal'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'

import { parseToInteger, parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../../common/mappers/FormatDate'
import { useEnviro } from '../../../../common/hooks/useEnviro'
import { ModalDeleteDeclaration } from './ModalDeleteDeclaration'

export const ModalDeclaration = ({
  proposalId,
  isOpened = true,
  handleClose,
  element = {},
  deleteDeclaration,
  getDeclarations,
}) => {
  const [modalDeleteDeclaration, setModalDeleteDeclaration] = useState(false)
  const { addDeclaration, editDeclaration } = useEnviro()
  const title = element?.declaredAt
    ? `Editar declaración de ejecución (${
        FormatDate.hydrate(element?.declaredAt).readableReverseDate
      })`
    : 'Declaración de ejecución'

  const getAmountRecycledPercentage = (amount, amountRecycled) => {
    if (amount === 0) return 0
    const amountParsed = amount
    const amountRecycledParsed = amountRecycled

    return parseToString((amountRecycledParsed / amountParsed) * 10000, 2)
  }

  const getAmountRecycledByPercentage = (amount, percentage) => {
    const amountParsed = parseToInteger(amount, 3) / 1000
    const percentageParsed = parseToInteger(percentage, 2) / 100

    return (amountParsed * percentageParsed) / 100
  }

  const initialValues = {
    itemDeclarations:
      element?.itemDeclarations?.map((item) => ({
        proposalItemId: item.proposalItemId,
        amount: parseToString(item.declaredAmount, 3),
        amountRecycled: getAmountRecycledPercentage(
          item.declaredAmount,
          item.declaredAmountRecycled,
        ),
      })) || [],
  }
  const action = async (values, formikHelpers) => {
    const mappedValues = {
      proposalItems: values.itemDeclarations.map((item) => ({
        proposalItemId: item.proposalItemId,
        amount: parseToInteger(item.amount, 3) || 0,
        amountRecycled:
          parseToInteger(
            getAmountRecycledByPercentage(item.amount, item.amountRecycled).toFixed(3),
            0,
          ) || 0,
      })),
    }

    if (element?.declaredAt) {
      await editDeclaration(proposalId, element?.declarationId, mappedValues)
    } else {
      const newDeclarationId = uuidv4()
      await addDeclaration(proposalId, newDeclarationId, mappedValues)
    }
    formikHelpers.resetForm()
    await getDeclarations(proposalId)
    handleClose()
  }

  const removeDeclaration = async () => {
    await deleteDeclaration(proposalId, element?.declarationId)
    await getDeclarations(proposalId)
    handleCloseDelete()
    handleClose()
  }

  const handleCloseDelete = () => setModalDeleteDeclaration(false)

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: action,
    enableReinitialize: true,
  })

  return (
    <>
      <Modal
        isOpen={isOpened}
        id="declaration-modal"
        onModalClose={handleClose}
        title={title}
        closeWithButton
        minWidth="826px">
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Modal.Content>
            <Grid
              gridTemplateAreas={"'proposalItem unit offered amount amountRecycled'"}
              gridTemplateColumns="1fr 125px 125px 150px 150px"
              bg="gray4"
              width="100%"
              paddingY={4}
              borderRadius={2}>
              <GridItem gridArea="proposalItem">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium">
                  Partida
                </Text>
              </GridItem>
              <GridItem gridArea="unit">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium">
                  Unidades
                </Text>
              </GridItem>
              <GridItem gridArea="offered">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Cantidad ofertada
                </Text>
              </GridItem>
              <GridItem gridArea="amount">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  Cantidad ejecutada
                </Text>
              </GridItem>
              <GridItem gridArea="amountRecycled">
                <Text
                  sizeText="display12"
                  paddingX={4}
                  color="gray1"
                  overflow="hidden"
                  fontWeight="medium"
                  textAlign="right">
                  % materiales reciclados
                </Text>
              </GridItem>
            </Grid>
            {values?.itemDeclarations?.length > 0 &&
              element?.itemDeclarations?.map((declaration, declarationIndex) => (
                <Grid
                  key={declaration?.proposalItemId}
                  gridTemplateAreas={"'proposalItem unit offered amount amountRecycled'"}
                  gridTemplateColumns="1fr 125px 125px 150px 150px"
                  paddingY={5}
                  width="100%"
                  borderBottomColor="gray4"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px">
                  <GridItem
                    gridArea="proposalItem"
                    display="flex"
                    style={{ alignItems: 'flex-start' }}>
                    <Text sizeText="display14" fontWeight="medium" color="gray1" paddingX={4}>
                      {declaration.name}
                    </Text>
                  </GridItem>
                  <GridItem gridArea="unit" display="flex" style={{ alignItems: 'flex-start' }}>
                    <Text sizeText="display12" color="gray1" paddingX={4}>
                      {declaration.unit}
                    </Text>
                  </GridItem>
                  <GridItem
                    gridArea="offered"
                    display="flex"
                    style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                    <Text sizeText="display12" color="gray1" paddingX={4}>
                      {parseToString(declaration.measurement, 3)}
                    </Text>
                  </GridItem>
                  <GridItem
                    gridArea="amount"
                    display="flex"
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: '0 16px',
                    }}>
                    <InputNumber
                      name={`itemDeclarations[${declarationIndex}].amount`}
                      placeholderMessage="0,000"
                      ariaLabel="amount"
                      value={values?.itemDeclarations[declarationIndex]?.amount}
                      onChange={handleChange}
                      numeralDecimalScale={3}
                    />
                  </GridItem>
                  <GridItem
                    gridArea="amountRecycled"
                    display="flex"
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: '0 16px',
                    }}>
                    <InputNumber
                      name={`itemDeclarations[${declarationIndex}].amountRecycled`}
                      placeholderMessage="0,00"
                      ariaLabel="amountRecycled"
                      value={values?.itemDeclarations[declarationIndex]?.amountRecycled}
                      onChange={handleChange}
                      numeralDecimalScale={2}
                    />
                  </GridItem>
                </Grid>
              ))}
            {element?.declaredAt && (
              <Text
                sizeText="display14"
                color="red1"
                paddingTop={5}
                onClick={() => setModalDeleteDeclaration(true)}
                style={{ cursor: 'pointer' }}>
                Eliminar declaración
              </Text>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Flex justifyContent="flex-end">
              <Grid gridTemplateRows="1fr" paddingRight={5}>
                <Button colorType="transparent" fullWidth action={handleClose}>
                  Cancelar
                </Button>
              </Grid>
              <Grid gridTemplateRows="1fr">
                <Button colorType="orange" fullWidth action={handleSubmit}>
                  {element?.declaredAt ? 'Guardar' : 'Crear declaración'}
                </Button>
              </Grid>
            </Flex>
          </Modal.Actions>
        </form>
      </Modal>

      <ModalDeleteDeclaration
        isOpened={modalDeleteDeclaration}
        handleClose={handleCloseDelete}
        handleDeleteDeclaration={removeDeclaration}
      />
    </>
  )
}
