import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout/Layout'
import { parseToString } from '../../../../../lib/utils/Format'
import { FormatDate } from '../../../../common/mappers/FormatDate'

export const DetailProposalTransport = ({ element, handleEditDeclaration }) => (
  <Grid
    gridTemplateAreas={"'material date type km weight kgEmit'"}
    gridTemplateColumns="1fr 100px 145px 110px 110px 110px"
    paddingY={5}
    width="100%"
    borderBottomColor="gray4"
    borderBottomStyle="solid"
    borderBottomWidth="1px">
    <GridItem gridArea="material">
      <Text
        sizeText="display12"
        paddingX={4}
        color="gray1"
        overflow="hidden"
        fontWeight="medium"
        onClick={() => handleEditDeclaration(element)}
        style={{ cursor: 'pointer' }}>
        {element.materialName}
      </Text>
    </GridItem>
    <GridItem gridArea="date">
      <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden">
        {FormatDate.hydrate(element.transportedAt).readableReverseDate}
      </Text>
    </GridItem>
    <GridItem gridArea="type">
      <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden">
        {element.vehicleName}
      </Text>
    </GridItem>
    <GridItem gridArea="km">
      <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden" textAlign="right">
        {parseToString(element.distance, 2)}
      </Text>
    </GridItem>
    <GridItem gridArea="weight">
      <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden" textAlign="right">
        {parseToString(element.weight, 2)}
      </Text>
    </GridItem>
    <GridItem gridArea="kgEmit">
      <Text sizeText="display12" paddingX={4} color="gray1" overflow="hidden" textAlign="right">
        {parseToString(element.eCo2, 2)}
      </Text>
    </GridItem>
  </Grid>
)
