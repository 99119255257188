import jwtDecode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { requestApi } from '../../../lib/request'
import { trakerLogin } from '../../../lib/utils/traker'
import { setLocalStorage } from '../functions/cookies'
import {
  authFails,
  cancelInvitationSuccess,
  checkLoginSuccess,
  getInvitationSuccess,
  setInvitationSuccess,
  setSignUpSuccess,
} from '../schemes/auth'
import { services } from '../schemes/auth/config'
import { AuthMapper, TokenMapper, AuthMapperOld } from '../schemes/auth/mapper'
import { useEmployee } from './useEmployee'
import { useUtils } from './useUtils'

export const useAuth = () => {
  const dispatch = useDispatch()
  const { setLoading, setError, setAlert } = useUtils()

  const { getEmployee } = useEmployee()

  const checkLogin = async (body) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.authCheck(body))
      const {
        roles: [role],
      } = jwtDecode(data.token)
      if (role === 'ROLE_Admin') {
        throw new Error('El usuario es administrador, no puede acceder a esta página.')
      }

      const loginData = TokenMapper.hydrate(data)
      dispatch(checkLoginSuccess(loginData))
      setLocalStorage(
        process?.env?.REACT_APP_COOKIE_LOGIN_NAME || env?.REACT_APP_COOKIE_LOGIN_NAME,
        data?.token,
      )
      const employee = await getEmployee()
      if (employee) {
        trakerLogin(employee.employeeId, {
          name: employee.name,
          email: employee.email,
          role: employee.employeeRole,
          type: employee.type,
          company_name: employee.companyId,
        })
      }
      setLoading(false)
      return loginData
    } catch (error) {
      setLoading(false)
      authFails(error)
      setError(error)
      return false
    }
  }

  const setSignUp = async ({ body }) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.setSignUp(AuthMapper.dehydrate(body)))
      dispatch(setSignUpSuccess(data))
      await checkLogin({ username: body.email, password: body.password })
    } catch (error) {
      authFails(error)
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const getInvitation = async (id) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await requestApi(services.getInvitation(id))
      dispatch(getInvitationSuccess(AuthMapper.hydrate(data)))
      setLoading(false)
      return data
    } catch (error) {
      authFails(error)
      setError(error)
      setLoading(false)
      return null
    }
  }

  const setInvitation = async ({ id, body }) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.setInvitation(id, AuthMapper.dehydrate(body)))
      dispatch(setInvitationSuccess(data))
      await checkLogin({ username: body.email, password: body.password })
    } catch (error) {
      authFails(error)
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const setInvitationOld = async ({ id, body }) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.setInvitation(id, AuthMapperOld.dehydrate(body)))
      dispatch(setInvitationSuccess(data))
      await checkLogin({ username: body.email, password: body.password })
    } catch (error) {
      authFails(error)
      setError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const cancelInvitation = async (id) => {
    setLoading(true)
    try {
      const { data } = await requestApi(services.cancelInvitation(id))
      dispatch(cancelInvitationSuccess())
      setLoading(false)
      return data
    } catch (error) {
      authFails(error)
      setError(error)
      setLoading(false)
      return null
    }
  }

  const setRestorePasswordAuth = async (hash, body) => {
    setLoading(true)
    try {
      await requestApi(services.setResetPasswordAuth(hash, body))
      setAlert('success', 'Tu contraseña se ha cambiado correctamente.')
    } catch (error) {
      authFails(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const setRestorePassword = async (email) => {
    setLoading(true)
    try {
      await requestApi(services.setResetPassword(email))
      setAlert(
        'success',
        'Se ha enviado un correo con las instrucciones para restaurar tu contraseña.',
      )
    } catch (error) {
      authFails(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   * @param {Object} body
   * @param {string} email
   * @param {string} taxCode
   * @returns {Promise<boolean>}
   */
  const preCheck = async (body) => {
    try {
      await requestApi(services.authPreCheck(body))
      return true
    } catch (error) {
      if (error.code !== 400) setError(error)
      return false
    }
  }

  return {
    checkLogin,
    setSignUp,
    getInvitation,
    setInvitation,
    setInvitationOld,
    cancelInvitation,
    setRestorePasswordAuth,
    setRestorePassword,
    preCheck,
  }
}
