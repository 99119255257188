import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { Close } from '@proveoeng/uikit/dist/atoms/Icons'
import { Input } from '@proveoeng/uikit/dist/atoms/Input'
import { Box, Flex } from '@proveoeng/uikit/dist/atoms/Layout'
import { SelectInput } from '@proveoeng/uikit/dist/atoms/Select'
import { Textarea } from '@proveoeng/uikit/dist/atoms/Textarea'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'

import { paymentDaysOptions } from '../../config'

export const CreateBidPackage = ({
  setFormIsOpened,
  onSubmit,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  categoriesOptions,
}) => (
  <Box
    position="fixed"
    top={0}
    right={0}
    height="100%"
    minHeight="100vh"
    width="475px"
    boxShadow="topInner"
    backgroundColor="white"
    padding={6}
    overflow="auto">
    <Flex justifyContent="space-between">
      <Text sizeText="display30" fontWeight="bold">
        Rellena la información básica de esta compra
      </Text>
      <Flex>
        <Icon sizeIcon="display18" color="gray1" onClick={() => setFormIsOpened(false)}>
          <Close />
        </Icon>
      </Flex>
    </Flex>
    <Flex as="form" onSubmit={onSubmit} flexDirection="column">
      <Field
        label="Nombre"
        required
        sizeText="display16"
        marginBottom={3}
        errorMessage={touched.name && errors.name}>
        <Input
          name="name"
          placeholderMessage="Da un nombre a tu proceso de compra"
          ariaLabel="Nombre"
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={touched.name && !!errors.name}
          value={values.name}
        />
      </Field>
      <Field
        label="Naturaleza"
        required
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.categoryId && errors.categoryId}>
        <SelectInput
          isSearchable
          name="categoryId"
          id="categoryId"
          onChange={(_, v) => setFieldValue('categoryId', v.value)}
          hasError={touched.categoryId && !!errors.categoryId}
          options={categoriesOptions}
          placeholderMessage="Selecciona a que naturaleza pertenece esta compra"
          ariaLabel="turnover"
        />
      </Field>
      <Field
        label="Descripción"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.description && errors.description}>
        <Textarea
          placeholderMessage="Describe las características de este proceso de compra para los proveedores"
          name="description"
          onChange={handleChange}
          value={values.description}
          hasError={touched.description && !!errors.description}
          maxLength="auto"
        />
      </Field>
      <Flex>
        <Flex
          width="100%"
          paddingRight={1}
          justifyContent="space-between"
          flexDirection="column"
          errorMessage={touched.bidEndsAt && errors.bidEndsAt}>
          <Field
            required
            label="Fecha límite para presentar oferta"
            sizeText="display16"
            marginBottom={3}
            marginTop={5}>
            <Input
              required
              type="date"
              ariaLabel="bidEndsAt"
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.bidEndsAt && !!errors.bidEndsAt}
              value={values.bidEndsAt}
              name="bidEndsAt"
              min={new Date().toISOString().split('T')[0]}
            />
          </Field>
        </Flex>
      </Flex>
      <Flex>
        <Flex width="50%" paddingRight={1} justifyContent="space-between" flexDirection="column">
          <Field
            label="Fecha inicio de entrega"
            sizeText="display16"
            marginBottom={3}
            marginTop={5}
            errorMessage={touched.start && errors.start}>
            <Input
              type="date"
              ariaLabel="start"
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.start && !!errors.start}
              value={values.start}
              name="start"
              min={new Date().toISOString().split('T')[0]}
            />
          </Field>
        </Flex>
        <Flex width="50%" paddingRight={1} justifyContent="space-between" flexDirection="column">
          <Field
            label="Fecha fin de entrega"
            sizeText="display16"
            marginBottom={3}
            marginTop={5}
            errorMessage={touched.end && errors.end}>
            <Input
              type="date"
              ariaLabel="end"
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.end && !!errors.end}
              value={values.end}
              name="end"
              min={values?.start || new Date().toISOString().split('T')[0]}
            />
          </Field>
        </Flex>
      </Flex>
      <Field
        label="Retención (%)"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.retention && errors.retention}>
        <Input
          name="retention"
          placeholderMessage="Indica el % de retención por garantía"
          ariaLabel="Retención"
          hasError={touched.retention && !!errors.retention}
          onChange={handleChange}
          value={values?.retention}
          onBlur={handleBlur}
        />
      </Field>
      <Field
        label="Forma de pago"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.paymentConditions && errors.paymentConditions}>
        <Input
          placeholderMessage="Escribe aquí tus condiciones de pago"
          ariaLabel="Nombre"
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={touched.paymentConditions && !!errors.paymentConditions}
          value={values.paymentConditions}
          name="paymentConditions"
        />
      </Field>
      <Field
        label="Días de pago"
        required
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.paymentDays && errors.paymentDays}>
        <SelectInput
          isSearchable
          required
          name="paymentDays"
          id="paymentDays"
          onChange={(_, v) => setFieldValue('paymentDays', v.value)}
          options={paymentDaysOptions}
          placeholderMessage="Seleccionar los días de pago"
          ariaLabel="paymentDays"
          onBlur={handleBlur}
          hasError={touched.paymentDays && !!errors.paymentDays}
          defaultValue={
            values?.paymentDays && paymentDaysOptions.find((el) => el.value === values?.paymentDays)
          }
        />
      </Field>
      <Field
        label="Condiciones generales para el proveedor"
        sizeText="display16"
        marginBottom={3}
        marginTop={5}
        errorMessage={touched.generalConditions && errors.generalConditions}>
        <Textarea
          placeholderMessage="Describe las condiciones de este proceso de compra para los proveedores. Podrás adjuntar archivos en el siguiente paso."
          name="generalConditions"
          onChange={handleChange}
          onBlur={handleBlur}
          hasError={touched.generalConditions && !!errors.generalConditions}
          value={values.generalConditions}
          maxLength="auto"
        />
      </Field>
      <Button type="submit" colorType="orange" fullWidth marginTop={5}>
        Siguiente
      </Button>
    </Flex>
  </Box>
)
