import { Box } from '@proveoeng/uikit/dist/atoms/Layout'
import { ProjectEnergySpend } from './Energy/ProjectEnergySpend'
import { ProjectFuelSpend } from './Fuel/ProjectFuelSpend'
import { ProjectWaterSpend } from './Water/ProjectWaterSpend'

export const ProjectSpend = ({ project }) => (
  <>
    <Box paddingBottom={6}>
      <ProjectEnergySpend project={project} />
    </Box>
    <Box paddingBottom={6}>
      <ProjectFuelSpend project={project} />
    </Box>
    <Box paddingBottom={6}>
      <ProjectWaterSpend project={project} />
    </Box>
  </>
)
