import { Icon } from '@proveoeng/uikit/dist/atoms/Icon'
import { ArrowDown, ArrowRight } from '@proveoeng/uikit/dist/atoms/Icons'
import { Flex, Grid, GridItem } from '@proveoeng/uikit/dist/atoms/Layout'
import { Text } from '@proveoeng/uikit/dist/atoms/Typography'
import { useState } from 'react'
import { FormatDate } from '../../../../common/mappers/FormatDate'
import { parseToString } from '../../../../../lib/utils/Format'

export const ProposalDeclarationsDetails = ({ declaration }) => {
  const [isDeclarationOpened, setIsDeclarationOpened] = useState(false)

  return (
    <>
      <Flex alignItems="center" paddingX={3} paddingY={3} width="100%" bg="white2">
        <Icon
          style={{ cursor: 'pointer' }}
          sizeIcon="display18"
          color="black"
          marginRight={4}
          onClick={() => setIsDeclarationOpened(!isDeclarationOpened)}>
          {isDeclarationOpened ? <ArrowDown /> : <ArrowRight />}
        </Icon>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text
            as="span"
            sizeText="display16"
            color="black"
            fontWeight="medium"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsDeclarationOpened(!isDeclarationOpened)}>
            {`Declaración ${
              FormatDate.hydrate(declaration?.resource?.declaredAt)?.readableReverseDate
            }`}
          </Text>
        </Flex>
      </Flex>

      {isDeclarationOpened &&
        declaration?.resource?.itemDeclarations?.map((d) => (
          <Grid
            key={d?.proposalItemId}
            gridTemplateAreas={
              "'proposalItem unit offered executed accumulated kg total percentageRecycled kgRecycled kgRecycledTotal'"
            }
            gridTemplateColumns="1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px"
            paddingY={5}
            width="100%"
            borderBottomColor="gray4"
            borderBottomStyle="solid"
            borderBottomWidth="1px">
            <GridItem gridArea="proposalItem" display="flex" style={{ alignItems: 'flex-start' }}>
              <Text sizeText="display12" color="gray1" paddingX={4} paddingLeft={8}>
                {d?.name}
              </Text>
            </GridItem>
            <GridItem gridArea="unit" display="flex" style={{ alignItems: 'flex-start' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {d?.unit}
              </Text>
            </GridItem>
            <GridItem
              gridArea="offered"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.measurement, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="executed"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.declaredAmount, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="accumulated"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.accumulatedAmount, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kg"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.unitaryECo2, 2)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="total"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.accumulatedECo2, 2)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="percentageRecycled"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {((d?.accumulatedAmountRecycled / d?.accumulatedAmount) * 100).toFixed(2)}%{' '}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kgRecycled"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.declaredAmountRecycled, 3)}
              </Text>
            </GridItem>
            <GridItem
              gridArea="kgRecycledTotal"
              display="flex"
              style={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <Text sizeText="display12" color="gray1" paddingX={4}>
                {parseToString(d?.accumulatedAmountRecycled, 3)}
              </Text>
            </GridItem>
          </Grid>
        ))}
    </>
  )
}
