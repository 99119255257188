import { useEffect } from 'react'
import { useFormik } from 'formik'

import { Field } from '@proveoeng/uikit/dist/atoms/Field'
import { Button } from '@proveoeng/uikit/dist/atoms/Button'
import { Flex, Grid } from '@proveoeng/uikit/dist/atoms/Layout'
import { Modal } from '@proveoeng/uikit/dist/molecules/Modal'
import { InputNumber } from '@proveoeng/uikit/dist/atoms/InputNumber'
import { parseToString } from '../../../../../../lib/utils/Format'

export const ModalProjectFuelSpend = ({
  isOpened = false,
  handleClose,
  data = [],
  addFuelDeclaration,
}) => {
  const initialValues = {
    declaredAmount: parseToString(data?.declaredAmount, 3) || '',
  }

  const { values, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues,
    onSubmit: addFuelDeclaration,
    enableReinitialize: true,
  })

  useEffect(() => {
    resetForm()
  }, [data])

  return (
    <Modal
      isOpen={isOpened}
      id="declaration-fuel-modal"
      onModalClose={handleClose}
      title="Declaración de consumo de gasoil"
      closeWithButton
      width="480px">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Modal.Content>
          <Field label="Litros" required sizeText="display14" marginBottom={3}>
            <InputNumber
              required
              name="declaredAmount"
              placeholderMessage="0,00"
              ariaLabel="declaredAmount"
              value={values?.declaredAmount}
              onChange={handleChange}
              numeralDecimalScale={3}
            />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          <Flex justifyContent="flex-end">
            <Grid gridTemplateRows="1fr" paddingRight={5}>
              <Button colorType="transparent" fullWidth action={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid gridTemplateRows="1fr">
              <Button
                colorType="orange"
                fullWidth
                action={() => {
                  addFuelDeclaration(values, data?.declarationId)
                  handleClose()
                }}>
                {data?.declaredAt ? 'Guardar' : 'Añadir declaración'}
              </Button>
            </Grid>
          </Flex>
        </Modal.Actions>
      </form>
    </Modal>
  )
}
